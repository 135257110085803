import React from "react";
import PageNotFound from "./PageNotFound";
import RegisterAuthenticator from "./RegisterAuthenticator";
import AuthenticatorList from "./authenticator/AuthenticatorList";
import AuthenticatorAdd from "./authenticator/AuthenticatorAdd";
import AuthenticatorDetail from "./authenticator/AuthenticatorDetail";
import AuditLogList from "./auditlog/AuditLogList";
import Dashboard from "./Dashboard";
import FidoAuthRequestPage from "./FidoAuthRequestPage";
import WebPushSubscriptionList from "./webpush/WebPushSubscriptionList";
import AuthorizationRequestList from "./authorizationrequest/AuthorizationRequestList";
import ChangePassword from "./ChangePassword";
import ActiveSessionList from "./session/ActiveSessionList";

export const routesMap = {
    Dashboard: {
        path: "/",
        element: <Dashboard/>,
        exact: true,
        menuKey: "dashboard"
    },
    AuthenticatorDetail: {
        path: "/authenticators/:authenticatorId",
        element: <AuthenticatorDetail/>,
        menuKey: "authenticator-list",
    },
    AuthenticatorList: {
        path: "/authenticators",
        element: <AuthenticatorList/>,
        menuKey: "authenticator-list",
    },
    AuthenticatorAdd: {
        path: "/add-authenticator",
        element: <AuthenticatorAdd/>,
        menuKey: "authenticator-list",
    },
    AuditLogList: {
        path: "/audit-logs",
        element: <AuditLogList/>,
        menuKey: "audit-log-list",
    },
    AuthorizationRequestList: {
        path: "/authorization-requests",
        element: <AuthorizationRequestList/>,
        menuKey: "authorization-request"
    },
    WebPushSubscriptionList: {
        path: "/web-push-subscriptions",
        element: <WebPushSubscriptionList/>,
        menuKey: "web-push-subscription"
    },
    RegisterAuthenticator: {
        path: "/register/:invitationId",
        element: <RegisterAuthenticator/>,
        menuKey: "register-authenticator"
    },
    ActiveSessionList: {
        path: "/active-sessions",
        element: <ActiveSessionList/>,
        menuKey: "active-session-list"
    },
    FidoAuthRequestPage: {
        path: "/fido/auth-request/:authRequestId",
        element: <FidoAuthRequestPage/>
    },
    ChangePassword: {
        path: "/change-password",
        element: <ChangePassword/>,
    },
    NotFound: {
        element: <PageNotFound/>,
    },
};

// @ts-ignore
export const routes = Object.keys(routesMap).map(key => routesMap[key]);

import {IntlMessageFormatter} from "../createIntlMessage";

export enum SessionType {
    SSO = "SSO",
    USER = "USER"
}

export function formatSessionType(intlMessage: IntlMessageFormatter, sessionType?: SessionType) {
    switch (sessionType) {
        case SessionType.SSO:
            return intlMessage("session-type.SSO");
        case SessionType.USER:
            return intlMessage("session-type.USER");
        default:
            return '?';
    }
}
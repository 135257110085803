import {Alert, Col, Layout, Row, Spin} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import {DocumentTitle} from "./DocumentTitle";
import {AppContextContext, AuthRequestServiceContext} from "../Contexts";
import SelectLang from "./SelectLang";
import {useIntlMessage} from "../createIntlMessage";
import styles from "./FidoAuthRequestPage.module.css";
import FidoAuthRequestDetail from "./FidoAuthRequestDetail";
import AuthRequest from "../domain/AuthRequest";

const {Header, Content, Footer} = Layout;

const layout = {xs: 24, sm: 15, md: 14, lg: 10, xl: 7, xxl: 6};

function FidoAuthRequestPage() {
    const appContext = useContext(AppContextContext);
    const intlMessage = useIntlMessage("fido-auth-request-page");
    const authRequestService = useContext(AuthRequestServiceContext);
    const {authRequestId}: any = useParams();
    const [authRequest, setAuthRequest] = useState<AuthRequest | null>();
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        authRequestService.get(authRequestId)
            .then(value => {
                setAuthRequest(value);
            })
            .catch(() => {
                setAuthRequest(null);
            })
    }, [authRequestId]);

    const title = intlMessage("title");

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${title}`}>
            <Layout className={styles.layout}>
                <Header className={styles.header}>
                    <div className={styles.logo}>
                        {appContext.config?.logo !== "" ?
                            <img src={appContext.config?.logo} alt={appContext.config?.fqdn}/> :
                            <img src="/logo.svg" alt={appContext.config?.fqdn}/>
                        }
                    </div>

                    <SelectLang/>
                </Header>

                <Content>
                    <Row justify={"space-around"} align={"middle"}>
                        <Col className={styles.content} {...layout}>
                            <Spin spinning={authRequest === undefined}>
                                {authRequest && <FidoAuthRequestDetail authRequest={authRequest} onAllowed={onAllowed} onExpired={onExpired}/>}

                                {allowed && renderAllowed()}

                                {authRequest == null && !allowed && renderOnNotFound()}
                            </Spin>
                        </Col>
                    </Row>
                </Content>

                <Footer style={{textAlign: 'center'}}>
                    <a href={"https://www.sonpo.cz"} target={"_blank"} rel="noopener noreferrer">
                        SONPO, a.s. © 2023
                    </a>
                </Footer>
            </Layout>
        </DocumentTitle>
    )

    function onAllowed() {
        setAllowed(true);

        setAuthRequest(null);
    }

    function onExpired() {
        setAuthRequest(null);
    }

    function renderAllowed() {
        return (
            <>
                <Alert type={"success"} message={intlMessage("request-allowed-1")}/>

                <div style={{marginTop: '1em'}}>{intlMessage("request-allowed-2")}</div>
            </>
        )
    }

    function renderOnNotFound() {
        return (
            <div>
                {intlMessage("not-exists")}
            </div>
        )
    }

}

export default FidoAuthRequestPage;
import {message} from 'antd';
import Axios from "axios";
import _ from 'lodash';
import * as NProgress from "nprogress";
import 'nprogress/nprogress.css';
import * as React from "react";
import {AppContext} from "../AppContext";
import {formatIntlMessage} from "../createIntlMessage";
import {intl} from "../Intl";
import dayjs from "dayjs";

class AxiosService {

    public static setUpInterceptors(appContext: AppContext) {
        Axios.defaults.baseURL = "/api/user"
        Axios.defaults.withCredentials = true;
        Axios.defaults.xsrfCookieName = 'User-XSRF-TOKEN';
        Axios.defaults.headers.common = {"X-Requested-With": "XMLHttpRequest"};

        NProgress.configure({showSpinner: false});

        Axios.interceptors.request.use(config => {
            const headers = config.headers || {};

            config.headers = Object.assign(headers, {common: {'Accept-Language': appContext.language, 'X-Timezone-Offset': dayjs().utcOffset()}});

            if (appContext.actionConfirmationCode) {
                config.headers = Object.assign(config.headers, {common: {'X-Action-Confirmation-Code': appContext.actionConfirmationCode}})
            }

            NProgress.start();

            return config
        });

        Axios.interceptors.response.use(response => {
                NProgress.done();

                if (response.headers['x-session-expires-at']) {
                    appContext.lastActivity = new Date();
                    appContext.sessionExpiresAt = new Date(response.headers['x-session-expires-at']);
                }

                return response
            }, error => {
                const intlMessage = _.partial(formatIntlMessage, intl, "");

                NProgress.done();

                if (error.response) {
                    const ignoreInterceptorHeader = error.response.headers['x-ignore-response-interceptor'];

                    if (ignoreInterceptorHeader && parseInt(ignoreInterceptorHeader, 10) === error.response.status) {
                        // handled in components
                    } else if (error.response.status === 200) {
                        // handled in components
                    } else if (error.response.status === 201) {
                        // handled in components
                    } else if (error.response.status === 202) {
                        // handled in components
                    } else if (error.response.status === 400) {
                        // handled in components
                    } else if (error.response.status === 401) {
                        appContext.user = undefined;
                    } else if (error.response.status === 403) {
                        message.error((
                            <React.Fragment>
                                <b>{intlMessage("error.server-forbidden")}</b>{(error.response.data) ? `: {JSON.stringify(error.response.data)` : ''}
                            </React.Fragment>
                        ), 5);
                    } else if (error.response.status === 404) {
                        // handled in components
                    } else if (error.response.status === 409) {
                        message.error((
                            <React.Fragment>
                                {intlMessage("error.object-cannot-be-deleted-because-its-used")}
                            </React.Fragment>
                        ), 5);
                    } else if (error.response.status === 410) {
                        // handled in components
                    } else if (error.response.status === 412) {
                        // handled in components
                    } else if (error.response.status === 500) {
                        message.error((
                            <React.Fragment>
                                <b>{intlMessage("error.server-error") || "Server error"}</b>: {JSON.stringify(error.response.data)}
                            </React.Fragment>
                        ), 5);
                    } else {
                        message.error((
                            <React.Fragment>
                                <b>{intlMessage("error.unexpected-response")}</b>
                            </React.Fragment>
                        ), 5);
                    }
                }

                return Promise.reject(error);
            }
        );
    }
}

export default AxiosService;

import Axios from "axios";

class FidoService {

    public startRegistration(authenticatorName: string, discoverableCredential: boolean, userVerificationRequired: boolean, allowedDuplicateAuthenticatorId?: string): Promise<{ fidoChallengeId: string, publicKeyCredentialCreationOptions: any }> {
        return Axios.post("/fido/start-registration", {authenticatorName, discoverableCredential, userVerificationRequired, allowedDuplicateAuthenticatorId}).then(value => value.data)
    }

    public startInvitationRegistration(invitationId: string, discoverableCredential: boolean, userVerificationRequired: boolean): Promise<{ fidoChallengeId: string, publicKeyCredentialCreationOptions: any }> {
        return Axios.post(`/fido/start-registration/${invitationId}`, {discoverableCredential, userVerificationRequired}).then(value => value.data)
    }

    public startAssertionForAuthRequest(authRequestId: string): Promise<{ fidoChallengeId: string, publicKeyCredentialRequestOptions: any }> {
        return Axios.post(`/fido/start-assertion`, {authRequestId}).then(value => value.data)
    }

}

export default FidoService;
import {Button, Form, Input, Select, Switch, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import React from "react";
import {FormInstance} from "antd/es";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {IntlMessageFormatter} from "../../createIntlMessage";
import EnrollmentType from "../../domain/EnrollmentType";
import {AuthenticatorType, isInvitationSupported} from "../../domain/AuthenticatorType";
import {AuthenticatorProfile, deriveAttributesForAuthenticatorProfile, formatAuthenticatorProfile, formatAuthenticatorProfileHelp, getRelevantProfiles, suggestProfile} from "../../domain/AuthenticatorProfile";
import styles from "./AuthenticatorDetail.module.css";
import InvitationVerificationCodeEnrollment from "../../domain/InvitationVerificationCodeEnrollment";
import AuthenticatorSetupStatus from "../../service/AuthenticatorSetupStatus";
import TimeUnitInput from "../common/TimeUnitInput";
import {capitalize} from "../../utils/FormatUtils";

export function onChangeProfile(form: FormInstance, value: AuthenticatorProfile) {
    form.setFieldsValue(deriveAttributesForAuthenticatorProfile(value, form.getFieldsValue()));
}

function renderAuthenticatorProfileSelectOption(intlMessage: IntlMessageFormatter, profile: AuthenticatorProfile) {
    return (
        <Select.Option value={profile} label={formatAuthenticatorProfile(intlMessage, profile)}>
            <b>{formatAuthenticatorProfile(intlMessage, profile)}</b>

            <div className={styles.selectOptionHelp}>{formatAuthenticatorProfileHelp(intlMessage, profile)}</div>
        </Select.Option>
    )
}

export function renderAuthenticatorProfile(form: FormInstance, serverViolationsHolder: ServerConstraintViolationsHolder, intlMessage: IntlMessageFormatter, type: AuthenticatorType) {
    return (
        <>
            <Form.Item
                name={"profile"}
                label={intlMessage("authenticator.profile")}
                initialValue={suggestProfile()}
                hidden={type !== AuthenticatorType.FIDO}
                rules={[{required: true, message: intlMessage("common.value-is-required")}]}>
                <Select onChange={(value) => onChangeProfile(form, value)} optionLabelProp={"label"} listHeight={320}>
                    {getRelevantProfiles().map(value => renderAuthenticatorProfileSelectOption(intlMessage, value))}
                </Select>
            </Form.Item>
        </>
    )
}

export function renderEnrollment(form: FormInstance, serverViolationsHolder: ServerConstraintViolationsHolder, intlMessage: IntlMessageFormatter, type: AuthenticatorType, enrollment: EnrollmentType, setupStatus: AuthenticatorSetupStatus, verificationCodeEnrollment: InvitationVerificationCodeEnrollment) {
    return (
        <>
            <Form.Item name={["basicAttributes", "enrollment", "type"]} label={intlMessage("authenticator.enrollment")}>
                <Select optionLabelProp={"label"} listHeight={320}>
                    <Select.Option value={EnrollmentType.DIRECT} disabled={type === AuthenticatorType.MOBILE} label={intlMessage("authenticator.enrollment-direct")}>
                        <b>{intlMessage("authenticator.enrollment-direct")}</b>

                        <div className={styles.selectOptionHelp}>{intlMessage("authenticator.enrollment-direct-help")}</div>
                    </Select.Option>

                    {isInvitationSupported(type) && <>
                        <Select.Option value={EnrollmentType.INVITATION_NO_NOTIFICATION} label={intlMessage("authenticator.enrollment-invite-no-notification")}>
                            <b>{intlMessage("authenticator.enrollment-invite-no-notification")}</b>

                            <div className={styles.selectOptionHelp}>{intlMessage("authenticator.enrollment-invite-no-notification-help")}</div>
                        </Select.Option>
                    </>}
                </Select>
            </Form.Item>

            <Form.Item
                name={["basicAttributes", "verificationCodeEnrollment", "type"]}
                label={intlMessage("authenticator.verification-code-enrollment")}
                hidden={enrollment === EnrollmentType.DIRECT}>
                <Select optionLabelProp={"label"} listHeight={320}>
                    <Select.Option value={InvitationVerificationCodeEnrollment.DIRECT} label={intlMessage("authenticator.verification-code-enrollment-direct")}>
                        <b>{intlMessage("authenticator.verification-code-enrollment-direct")}</b>

                        <div className={styles.selectOptionHelp}>{intlMessage("authenticator.verification-code-enrollment-direct-help")}</div>
                    </Select.Option>

                    <Select.Option value={"NONE"} label={intlMessage("authenticator.verification-code-enrollment-none")}>
                        <b>{intlMessage("authenticator.verification-code-enrollment-none")}</b>

                        <div className={styles.selectOptionHelp}>{intlMessage("authenticator.verification-code-enrollment-none-help")}</div>
                    </Select.Option>
                </Select>
            </Form.Item>

            {setupStatus && (enrollment === EnrollmentType.INVITATION_NO_NOTIFICATION || (enrollment === EnrollmentType.DIRECT && type === AuthenticatorType.MOBILE)) &&
                <Form.Item name={["basicAttributes", "enrollment", "invitationLifetimeInSeconds"]}
                           label={intlMessage("settings-security.invitation-lifetime")}
                           initialValue={setupStatus?.invitationLifetime}
                           rules={[{required: true, message: intlMessage("common.value-is-required")}]}>
                    <TimeUnitInput/>
                </Form.Item>
            }
        </>
    )
}

export function renderActivationFidoElements(form: FormInstance, serverViolationsHolder: ServerConstraintViolationsHolder, intlMessage: IntlMessageFormatter, type: AuthenticatorType, enrollment: EnrollmentType, profile: AuthenticatorProfile, discoverableCredential: boolean) {
    return (
        <>
            <Form.Item name={"discoverableCredential"}
                       label={intlMessage("authenticator.fido-discoverable-credential")}
                       extra={intlMessage("authenticator.fido-discoverable-credential-help")}
                       initialValue={true}
                       hidden={profile !== AuthenticatorProfile.GENERAL}
                       valuePropName={"checked"}>
                <Switch onChange={(value) => onDiscoverableCredentialChanged(form, value)} disabled={profile !== AuthenticatorProfile.GENERAL}/>
            </Form.Item>

            <Form.Item name={"userVerificationRequired"}
                       label={intlMessage("authenticator.fido-user-verification-required")}
                       extra={intlMessage("authenticator.fido-user-verification-required-help")}
                       initialValue={true}
                       hidden={profile !== AuthenticatorProfile.GENERAL}
                       valuePropName={"checked"}>
                <Switch disabled={discoverableCredential || profile !== AuthenticatorProfile.GENERAL}/>
            </Form.Item>
        </>
    )
}

export function renderAttributeMobileElements(form: FormInstance, serverViolationsHolder: ServerConstraintViolationsHolder, intlMessage: IntlMessageFormatter) {
    return (
        <Form.Item
            name={"phoneNumber"}
            label={intlMessage("common.phone-number")}>
            <Input autoFocus={true} maxLength={100}/>
        </Form.Item>
    )
}

export function renderActivationYubiKeyElements(form: FormInstance, serverViolationsHolder: ServerConstraintViolationsHolder, intlMessage: IntlMessageFormatter) {
    return (
        <>
            <Form.Item
                name={"yubiKeyOtp"}
                label={intlMessage("authenticator.yubi-key-otp")}
                extra={intlMessage("authenticator.yubi-key-otp-help")}
                rules={[
                    {required: true, message: intlMessage("authenticator.yubi-key-otp-required")},
                    {validator: serverViolationsHolder.createServerValidator('CUSTOM'), message: intlMessage("authenticator.yubi-key-otp-not-valid")},
                    {validator: serverViolationsHolder.createServerValidator('UNIQUE'), message: intlMessage("authenticator.yubi-key-otp-already-registered")}
                ]}>
                <Input autoFocus={true}/>
            </Form.Item>
        </>
    )
}

export function renderActivationTlsCertificateElements(form: FormInstance, serverViolationsHolder: ServerConstraintViolationsHolder, intlMessage: IntlMessageFormatter) {
    return (
        <>
            <Form.Item
                name={"certificate"}
                valuePropName={"file"}
                label={capitalize(intlMessage("authenticator.tls-certificate"))}
                extra={intlMessage("authenticator.tls-certificate-help")}
                rules={[
                    {required: true, message: intlMessage("common.value-is-required")},
                    {validator: serverViolationsHolder.createServerValidator('CUSTOM', 'certificate'), message: intlMessage("authenticator.tls-certificate-not-valid")},
                ]}>
                <Upload showUploadList={false}>
                    <Button icon={<UploadOutlined/>}>
                        {form.getFieldValue('certificate') ? form.getFieldValue('certificate').file.name : intlMessage("common.choose-file")}
                    </Button>
                </Upload>
            </Form.Item>
        </>
    );
}

function onDiscoverableCredentialChanged(form: FormInstance, checked: boolean) {
    if (checked) {
        form.setFieldsValue({userVerificationRequired: true});
    }
}

import {DeleteFilled, ReloadOutlined} from "@ant-design/icons";
import {Alert, Breadcrumb, Button, message, Modal, Switch, Table} from "antd";
import Column from "antd/es/table/Column";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DocumentTitle} from "../DocumentTitle";
import {AppContextContext, WebPushServiceContext} from "../../Contexts";
import {useIntlMessage} from "../../createIntlMessage";
import {useTableHandler} from "../../sal-ui/TableHandler";
import appStyles from "../../App.module.css";
import WebPushSubscription from "../../domain/WebPushSubscription";
import {fromByteArray} from "../../utils/base64url";
import styles from "./WebPushSubscriptionList.module.css";
import UAParser from "ua-parser-js";
import {AxiosError} from "axios";

function WebPushSubscriptionList() {
    const appContext = useContext(AppContextContext);
    const webPushService = useContext(WebPushServiceContext);
    const intlMessage = useIntlMessage("web-push-subscription-list");
    const tableHandler = useTableHandler("name asc", {reloadFunction: reload});
    const [subscriptions, setSubscriptions] = useState<WebPushSubscription[]>();
    const [pushSubscriptionActive, setPushSubscriptionActive] = useState<boolean>();
    const [subscriptionUpdateInProgress, setSubscriptionUpdateInProgress] = useState(false);
    const subscriptionRef = useRef<PushSubscription | null>(null);

    const webPushSupported = 'PushManager' in window;

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();
    }, [])

    if (webPushSupported && appContext.serviceWorkerRegistration) {
        const pushManager = appContext.serviceWorkerRegistration.pushManager;

        pushManager.getSubscription().then(value => {
            setPushSubscriptionActive(value != null);

            subscriptionRef.current = value;
        })
    }

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage("common.web-push-subscriptions")}`}>
            <>
                <Breadcrumb className={"common__breadcrumb"}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{intlMessage("common.web-push-subscriptions")}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{intlMessage("common.web-push-subscriptions")}</h1>

                <h3>{intlMessage("this-device")}</h3>

                <Alert message={intlMessage("web-push.intro")} style={{display: "inline-block"}}/>

                {
                    webPushSupported && pushSubscriptionActive !== undefined && !pushSubscriptionActive &&

                    <div className={styles.text}>
                        <Switch className={`default-button ${styles['notifications-toggle-button']}`}
                                checked={pushSubscriptionActive}
                                onClick={onTurnOnNotifications}
                                disabled={subscriptionUpdateInProgress}
                        />

                        {intlMessage("web-push.is-not-active")}

                    </div>
                }

                {
                    webPushSupported && pushSubscriptionActive !== undefined && pushSubscriptionActive &&

                    <div className={styles.text}>
                        <Switch className={`default-button ${styles['notifications-toggle-button']}`}
                                checked={pushSubscriptionActive}
                                onClick={onTurnOffNotifications}
                                disabled={subscriptionUpdateInProgress}
                        />

                        {intlMessage("web-push.is-active")}
                    </div>
                }

                <h3>{intlMessage("all-subscriptions")}</h3>

                <div className={appStyles.topButtonBar}>
                    <Button icon={<ReloadOutlined/>} onClick={tableHandler.reload} className={appStyles.btnSeamless}/>
                </div>

                <Table className={styles.table}
                       showSorterTooltip={false}
                       loading={tableHandler.loading}
                       dataSource={subscriptions}
                       size="middle"
                       onChange={tableHandler.onTableChange}
                       pagination={tableHandler.pagination}
                       rowKey="id">
                    <Column dataIndex="name" title={intlMessage("common.name")}/>

                    <Column title={intlMessage("common.action")} width='100px' render={renderAction} className={"table-actions"}/>
                </Table>
            </>
        </DocumentTitle>
    )

    function renderAction(value: any, subscription: WebPushSubscription, index: number) {
        return (
            <>
                <Button className={appStyles.btnSeamless}
                        icon={<DeleteFilled/>}
                        title={intlMessage("common.delete")}
                        onClick={() => {
                            Modal.confirm({
                                content: intlMessage("confirm-delete", {name: subscription.name}),
                                okText: intlMessage("common.delete"),
                                cancelText: intlMessage("common.cancel"),
                                okButtonProps: {danger: true},
                                onOk: () => onDeleteConfirm(subscription)
                            });
                        }}
                />
            </>
        )
    }

    function reload() {
        return webPushService.getList().then(subscriptions => {
            setSubscriptions(subscriptions.filter(value => value.endpoint !== subscriptionRef.current?.endpoint));

            tableHandler.updateTotal(subscriptions.length);
        });
    }

    function onDeleteConfirm(subscription: WebPushSubscription) {
        webPushService.deleteSubscription(subscription.endpoint!)
            .then(() => {
                message.success(intlMessage("deleted", {name: subscription.name}));

                reload();
            });
    }

    function onTurnOnNotifications() {
        Modal.confirm({
            content: intlMessage("web-push.activate-confirm"),
            okText: intlMessage("common.yes"),
            cancelText: intlMessage("common.cancel"),
            onOk: () => {
                setSubscriptionUpdateInProgress(true);

                Notification.requestPermission()
                    .then(permission => {
                        if (permission === 'denied') {
                            message.error(intlMessage("web-push.notifications-denied"));

                            return;
                        }

                        return appContext.serviceWorkerRegistration?.pushManager
                            .subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: appContext.authenticatedConfig?.webPushPublicKey
                            })
                            .then(subscription => {
                                const ua = UAParser(navigator.userAgent);

                                const name = (ua.browser.name && ua.os.name) ? `${ua.os.name} - ${ua.browser.name}` : navigator.userAgent;

                                return webPushService
                                    .addSubscription(name, subscription.endpoint, fromByteArray(subscription.getKey("p256dh")), fromByteArray(subscription.getKey("auth")))
                                    .catch((error) => {
                                        subscription.unsubscribe();

                                        return Promise.reject(error);
                                    })
                            })
                            .then(() => {
                                message.success(intlMessage("web-push.activation-succeeded"))

                                setPushSubscriptionActive(true);

                                reload();
                            })
                            .catch(() => {
                                message.error(intlMessage("web-push.activation-failed"))

                                return;
                            })
                    })
                    .finally(() => setSubscriptionUpdateInProgress(false));
            }
        });
    }

    function onTurnOffNotifications() {
        Modal.confirm({
            content: intlMessage("web-push.deactivate-confirm"),
            okText: intlMessage("common.yes"),
            cancelText: intlMessage("common.cancel"),
            okButtonProps: {danger: true},
            onOk: () => {
                setSubscriptionUpdateInProgress(true);

                appContext.serviceWorkerRegistration?.pushManager.getSubscription()
                    .then(subscription => {
                        if (subscription) {
                            subscription.unsubscribe()
                                .then(() => webPushService.deleteSubscription(subscription.endpoint))
                                .catch((reason: AxiosError) => {
                                    if (reason.response?.status === 404) {
                                        return;
                                    } else {
                                        return Promise.reject(reason);
                                    }
                                })
                                .then(() => {
                                    message.success(intlMessage("web-push.deactivation-succeeded"))

                                    setPushSubscriptionActive(false);

                                    reload();
                                })
                                .catch(() => message.error(intlMessage("web-push.deactivation-failed")))
                        }
                    })
                    .finally(() => setSubscriptionUpdateInProgress(false));
            }
        });
    }

}

export default WebPushSubscriptionList;
import styles from "./UserAgentItem.module.css";
import React from "react";
import {IBrowser, IOS} from "ua-parser-js";
import {SiAndroid, SiFirefox, SiGooglechrome, SiIos, SiMacos, SiMicrosoftedge, SiSafari, SiWindows} from "react-icons/si";
import {MdOutlineLaptopChromebook} from "react-icons/md";
import {GoBrowser} from "react-icons/go";

interface Props {
    left: React.ReactNode;
    center: React.ReactNode;
    right: React.ReactNode;
}

export function UserAgentItem({left, center, right}: Props) {
    return (
        <>
            <div className={styles['container']}>
                <div className={styles['item-left']}>
                    {left}
                </div>
                <div className={styles['item-center']}>
                    {center}
                </div>
                <div className={styles['item-right']}>
                    {right}
                </div>
            </div>
        </>
    )
}

export function renderIconForOs(os: IOS) {
    if (os.name?.toLowerCase().startsWith('windows')) {
        return <SiWindows className={"anticon"}/>
    } else if (os.name?.toLowerCase().startsWith('android')) {
        return <SiAndroid className={"anticon"}/>
    } else if (os.name?.toLowerCase().startsWith('ios')) {
        return <SiIos className={"anticon"}/>
    } else if (os.name?.toLowerCase().startsWith('macos')) {
        return <SiMacos className={"anticon"}/>
    } else {
        return <MdOutlineLaptopChromebook className={"anticon"}/>;
    }
}

export function renderIconForBrowser(browser: IBrowser) {
    if (browser.name?.toLowerCase().startsWith('chrome')) {
        return <SiGooglechrome className={"anticon"}/>;
    } else if (browser.name?.toLowerCase().startsWith('firefox')) {
        return <SiFirefox className={"anticon"}/>;
    } else if (browser.name?.toLowerCase().startsWith('edge')) {
        return <SiMicrosoftedge className={"anticon"}/>;
    } else if (browser.name?.toLowerCase().startsWith('safari')) {
        return <SiSafari className={"anticon"}/>;
    } else {
        return <GoBrowser className={"anticon"}/>;
    }
}

class User {
    constructor(
        public id?: string,
        public username?: string) {
        //
    }

    public static fromPOJO(data: any) {
        const user = new User();

        user.id = data.id;
        user.username = data.username;

        return user;
    }

    public get initials() {
        return this.username?.at(0)?.toUpperCase();
    }

}

export default User;

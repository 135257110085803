import React from "react";
import {AppContext} from "./AppContext";
import AuthenticatorService from "./service/AuthenticatorService";
import PreferenceService from "./service/PreferenceService";
import AuthService from "./service/AuthService";
import {ClientNotificationService} from "./sal-ui/ClientNotificationService";
import AuthenticatorAssignmentService from "./service/AuthenticatorAssignmentService";
import AuthenticatorSetupStatusService from "./service/AuthenticatorSetupStatusService";
import AuditLogService from "./service/AuditLogService";
import StatisticsService from "./service/StatisticsService";
import FidoService from "./service/FidoService";
import WebPushService from "./service/WebPushService";
import AuthRequestService from "./service/AuthRequestService";
import SessionService from "./service/SessionService";
import EnumService from "./service/EnumService";
import UserPortalService from "./service/UserPortalService";

export const appContext = new AppContext();
export const preferenceService = new PreferenceService();
export const authService = new AuthService(appContext);

const clientNotificationService = new ClientNotificationService();
const authenticatorAssignmentService = new AuthenticatorAssignmentService();
const authenticatorSetupStatusService = new AuthenticatorSetupStatusService();
const auditLogService = new AuditLogService();
const statisticsService = new StatisticsService();
const authenticatorService = new AuthenticatorService();
const fidoService = new FidoService();
const webPushService = new WebPushService();
const authRequestService = new AuthRequestService();
const sessionService = new SessionService();
const enumService = new EnumService(appContext);
const userPortalService = new UserPortalService();

export const AppContextContext = React.createContext(appContext);
export const AuthenticatorServiceContext = React.createContext(authenticatorService);
export const PreferenceServiceContext = React.createContext(preferenceService);
export const AuthServiceContext = React.createContext(authService);
export const ClientNotificationServiceContext = React.createContext(clientNotificationService);
export const AuthenticatorAssignmentServiceContext = React.createContext(authenticatorAssignmentService);
export const AuthenticatorSetupStatusServiceContext = React.createContext(authenticatorSetupStatusService);
export const AuditLogServiceContext = React.createContext(auditLogService);
export const StatisticsServiceContext = React.createContext(statisticsService);
export const FidoServiceContext = React.createContext(fidoService);
export const WebPushServiceContext = React.createContext(webPushService);
export const AuthRequestServiceContext = React.createContext(authRequestService);
export const SessionServiceContext = React.createContext(sessionService);
export const EnumServiceContext = React.createContext(enumService);
export const UserPortalServiceContext = React.createContext(userPortalService);

import React, {useContext, useEffect, useRef, useState} from "react";
import {DocumentTitle} from "./DocumentTitle";
import {AppContextContext, AuditLogServiceContext, AuthenticatorAssignmentServiceContext, UserPortalServiceContext} from "../Contexts";
import {Alert, Card, Col, Row, Select, Skeleton} from "antd";
import {useIntlMessage} from "../createIntlMessage";
import styles from "./Dashboard.module.css";
import AuthRequestStatistics from "./dashboard/AuthRequestStatistics";
import DateTimePeriod from "../domain/DateTimePeriod";
import ProtectedApplicationStatistics from "./dashboard/ProtectedApplicationStatistics";
import CountryAuthRequestStatistics from "./dashboard/CountryAuthRequestStatistics";
import MultiFactorMethodAuthRequestStatistics from "./dashboard/MultiFactorMethodAuthRequestStatistics";
import QueryOptions from "../sal-ui/QueryOptions";
import {ObjectAuditLogList, ObjectAuditLogListDelegate} from "./auditlog/ObjectAuditLogList";
import {Link} from "react-router-dom";
import {routesMap} from "./Routes";
import UserPortalApplication from "../domain/UserPortalApplication";

function Dashboard() {
    const appContext = useContext(AppContextContext);
    const auditLogService = useContext(AuditLogServiceContext);
    const authenticatorAssignmentService = useContext(AuthenticatorAssignmentServiceContext);
    const userPortalService = useContext(UserPortalServiceContext);
    const intlMessage = useIntlMessage("dashboard");
    const [period, setPeriod] = useState<DateTimePeriod>(DateTimePeriod.Month);
    const [authenticatorsCount, setAuthenticatorsCount] = useState<number>();
    const auditLogsRef = useRef<ObjectAuditLogListDelegate>(null);
    const [portalApplications, setPortalApplications] = useState<UserPortalApplication[]>();

    const title = intlMessage("title");

    const layout = {xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24};
    const chartLayout = {xs: 24, sm: 24, md: 12, lg: 12, xl: 6, xxl: 6};

    useEffect(() => {
        authenticatorAssignmentService.getAssignments().then(value => {
            setAuthenticatorsCount(value.total);
        });

        userPortalService.getUserPortalApplications().then(setPortalApplications);

        auditLogsRef.current?.refresh();
    }, [])

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${title}`}>
            <Row>
                <Col {...layout}>
                    <div>
                        {
                            authenticatorsCount !== undefined && authenticatorsCount <= 1 &&

                            <Alert className={styles['authenticators-warning']}
                                   showIcon={true}
                                   type={"warning"}
                                   message={
                                       (authenticatorsCount == 0)
                                           ? intlMessage("no-authenticators-warning", {link: (chunks: any) => (<Link to={routesMap.AuthenticatorAdd.path}>{chunks}</Link>)})
                                           : intlMessage("only-one-authenticator-warning", {link: (chunks: any) => (<Link to={routesMap.AuthenticatorAdd.path}>{chunks}</Link>)})
                                   }
                            />
                        }

                        <h2>{intlMessage("dashboard.quick-access")}</h2>

                        <Skeleton loading={portalApplications === undefined} />

                        {portalApplications !== undefined && portalApplications.length === 0 && <p>{intlMessage("dashboard.no-user-portal-applications")}</p>}

                        <div className={styles.portalApplicationList}>
                            {portalApplications?.map(application => (
                                <Card
                                    className={styles.portalApplication}
                                    title={<>
                                        <a href={application.indexUrl} target={"_blank"}>{application.name}</a>
                                    </>}
                                />
                            ))}
                        </div>

                        <h2>{intlMessage("authorization-requests")}</h2>

                        <Row gutter={16} className={styles.dashboard}>
                            <Col {...chartLayout}>
                                <Card size="small"
                                      title={intlMessage("auth-request-statistics")}
                                      extra={
                                          <Select onChange={onChangePeriod} value={period}>
                                              <Select.Option value="Day">{intlMessage("common.day")}</Select.Option>
                                              <Select.Option value="Week">{intlMessage("common.week")}</Select.Option>
                                              <Select.Option value="Month">{intlMessage("common.month")}</Select.Option>
                                              <Select.Option value="Quarter">{intlMessage("common.quarter")}</Select.Option>
                                          </Select>
                                      }>
                                    <AuthRequestStatistics period={period}/>
                                </Card>
                            </Col>

                            <Col {...chartLayout}>
                                <Card size="small"
                                      title={intlMessage("protected-application-statistics")}
                                      extra={
                                          <Select onChange={onChangePeriod} value={period}>
                                              <Select.Option value="Day">{intlMessage("common.day")}</Select.Option>
                                              <Select.Option value="Week">{intlMessage("common.week")}</Select.Option>
                                              <Select.Option value="Month">{intlMessage("common.month")}</Select.Option>
                                              <Select.Option value="Quarter">{intlMessage("common.quarter")}</Select.Option>
                                          </Select>
                                      }>
                                    <ProtectedApplicationStatistics period={period}/>
                                </Card>
                            </Col>

                            <Col {...chartLayout}>
                                <Card size="small"
                                      title={intlMessage("countries-statistics")}
                                      extra={
                                          <Select onChange={onChangePeriod} value={period}>
                                              <Select.Option value="Day">{intlMessage("common.day")}</Select.Option>
                                              <Select.Option value="Week">{intlMessage("common.week")}</Select.Option>
                                              <Select.Option value="Month">{intlMessage("common.month")}</Select.Option>
                                              <Select.Option value="Quarter">{intlMessage("common.quarter")}</Select.Option>
                                          </Select>
                                      }>
                                    <CountryAuthRequestStatistics period={period}/>
                                </Card>
                            </Col>

                            <Col {...chartLayout}>
                                <Card size="small"
                                      title={intlMessage("methods-statistics")}
                                      extra={
                                          <Select onChange={onChangePeriod} value={period}>
                                              <Select.Option value="Day">{intlMessage("common.day")}</Select.Option>
                                              <Select.Option value="Week">{intlMessage("common.week")}</Select.Option>
                                              <Select.Option value="Month">{intlMessage("common.month")}</Select.Option>
                                              <Select.Option value="Quarter">{intlMessage("common.quarter")}</Select.Option>
                                          </Select>
                                      }>
                                    <MultiFactorMethodAuthRequestStatistics period={period}/>
                                </Card>
                            </Col>
                        </Row>

                        <h2>{intlMessage("common.last-audit-logs")}</h2>

                        <Row gutter={16}>
                            <Col span={24}>
                                <ObjectAuditLogList loadData={reloadAuditLogs}
                                                    ref={auditLogsRef}
                                                    initialPageSize={10}
                                                    hidePrincipal={true}
                                                    simpleMode={true}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </DocumentTitle>
    )

    function onChangePeriod(value: DateTimePeriod) {
        setPeriod(DateTimePeriod[value]);
    }

    function reloadAuditLogs(queryOptions: QueryOptions) {
        return auditLogService.getListForUserFailures(queryOptions);
    }

}

export default Dashboard;
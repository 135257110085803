import Axios from "axios";
import DateTimePeriod from "../domain/DateTimePeriod";

class StatisticsService {

    public getStatistics(period: DateTimePeriod, statistics: string): Promise<any[]> {
        return Axios.get(`/statistics/${statistics}?period=${period.toLowerCase()}`).then(value => value.data);
    }

}

export default StatisticsService;
export function register() {
    if ('serviceWorker' in navigator) {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        return navigator.serviceWorker
            .register(swUrl)
            .then(registration => {
                console.log("Service worker registered successfully.");

                return registration;
            })
            .catch(error => {
                console.error('Error during service worker registration:', error);

                return error;
            });
    } else {
        return Promise.reject();
    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then(registration => {
                registration.unregister();
            })
            .catch(error => {
                console.error(error.message);
            });
    }
}

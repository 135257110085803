
import {AxiosError} from "axios";

export function undefinedIfNotFound(error: AxiosError) {
    return (error.response?.status === 404) ? Promise.resolve(undefined) : Promise.reject(error);
}

export function readFileAsText(inputFile: File): Promise<string> {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onerror = () => {
            reader.abort();
            reject(new DOMException("Problem parsing input file."));
        };

        reader.onload = () => {
            resolve(reader.result as string);
        };

        reader.readAsDataURL(inputFile);
    });
}

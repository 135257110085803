import {Button, Col, Descriptions, Form, Input, Layout, message, Modal, Row, Spin, Switch} from "antd";
import {useForm, useWatch} from "antd/es/form/Form";
import dayjs from "dayjs";
import React, {useContext, useEffect, useState} from "react";
import {BrowserView, MobileView} from 'react-device-detect';
import {DocumentTitle} from "./DocumentTitle";
import {useParams} from "react-router";
import {AppContextContext, AuthenticatorServiceContext, FidoServiceContext} from "../Contexts";
import {useIntlMessage} from "../createIntlMessage";
import Authenticator from "../domain/Authenticator";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";
import styles from "./RegisterAuthenticator.module.css";
import appStyles from "../App.module.css";
import {Link} from "react-router-dom";
import SelectLang from "./SelectLang";
import {AuthenticatorType} from "../domain/AuthenticatorType";
import {createExtended} from "@github/webauthn-json/extended";
import {AuthenticatorProfile, deriveAttributesForAuthenticatorProfile} from "../domain/AuthenticatorProfile";
import {renderAuthenticatorProfile} from "./authenticator/AuthenticatorFormHelper";

const {Header, Content} = Layout;

enum RegistrationFormState {
    REGISTRATION,
    DONE
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function RegisterAuthenticator() {
    const appContext = useContext(AppContextContext);
    const intlMessage = useIntlMessage("register-authenticator");
    const authenticatorService = useContext(AuthenticatorServiceContext);
    const fidoService = useContext(FidoServiceContext);
    const [authenticator, setAuthenticator] = useState<Authenticator>();
    const [loaded, setLoaded] = useState(false);
    const {invitationId, url, path}: any = useParams();
    const [form] = useForm();
    const discoverableCredential = useWatch<boolean>("discoverableCredential", form);
    const [registrationState, setRegistrationState] = useState<RegistrationFormState>(RegistrationFormState.REGISTRATION);
    const [inProgress, setInProgress] = useState(false);
    const profile = useWatch<AuthenticatorProfile>("profile", form);

    const layout = {xs: 24, sm: 24, md: 16, lg: 14, xl: 10, xxl: 12};

    const formLayout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    const tailFormLayout = (appContext.isTabletOrMobile)
        ? {wrapperCol: {span: 24}}
        : {wrapperCol: {offset: 8, span: 16}};

    useEffect(() => {
        authenticatorService
            .findByInvitationId(invitationId!)
            .then(setAuthenticator)
            .finally(() => setLoaded(true))
    }, []);

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage("title")}`}>
            <Layout style={{minHeight: '100vh'}}>
                <Header className={styles.header}>
                    <div className={styles.logo}>
                        <Link to={"/"}>
                            {appContext.config?.logo !== "" ?
                                <img src={appContext.config?.logo} alt={appContext.config?.fqdn}/> :
                                <img src="/logo.svg" alt={appContext.config?.fqdn}/>
                            }
                        </Link>
                    </div>

                    <div className={styles.center}/>

                    <div className={styles.toolbar}>
                        <SelectLang/>
                    </div>
                </Header>
                <Layout>
                    <Content className={styles.content}>
                        <Row justify={"center"}>
                            <Col {...layout}>
                                <Spin spinning={!loaded}>
                                    <h1>{intlMessage("title")}</h1>

                                    {loaded && !authenticator && intlMessage("request-not-found")}

                                    {registrationState === RegistrationFormState.DONE && intlMessage("register-authenticator-successful")}

                                    {
                                        registrationState === RegistrationFormState.REGISTRATION && authenticator &&

                                        <>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <p>
                                                            {authenticator.type === "MOBILE" && intlMessage("register-mobile-authenticator-help_pc")}
                                                            {authenticator.type === "YUBIKEY" && intlMessage("register-yubikey-authenticator-help_pc")}
                                                        </p>
                                                    </BrowserView>
                                                    <MobileView>
                                                        <p>
                                                            {authenticator.type === "MOBILE" && intlMessage("register-mobile-authenticator-help_mobile")}
                                                            {authenticator.type === "YUBIKEY" && intlMessage("register-yubikey-authenticator-help_mobile")}
                                                        </p>
                                                    </MobileView>
                                                </Col>
                                            </Row>

                                            <Descriptions column={1} bordered={true} className={`${appStyles.details} ${styles.details}`} size="small">
                                                <Descriptions.Item label={intlMessage("authenticator")}>{authenticator.name}</Descriptions.Item>
                                                <Descriptions.Item label={intlMessage("common.type")}>{Authenticator.formatType(intlMessage, authenticator.type)}</Descriptions.Item>
                                                {authenticator.type === "MOBILE" && <Descriptions.Item label={intlMessage("phone-number")}>{authenticator.phoneNumber}</Descriptions.Item>}
                                                <Descriptions.Item label={intlMessage("common.created-at")}>{dayjs(authenticator?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                                                <Descriptions.Item label={intlMessage("invitationExpiresAt")}>{dayjs(authenticator?.invitationExpiresAt).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                                            </Descriptions>

                                            <Form {...formLayout} form={form} onFinish={onFinishRegistration} className={`${appStyles.formHorizontal} ${styles.form}`}>
                                                <div className={appStyles.formSectionTitle}>{intlMessage("common.section-basic")}</div>

                                                {authenticator.invitationVerificationCodeRequired &&
                                                    <Form.Item
                                                        name={"verificationCode"}
                                                        label={intlMessage("verification-code")}
                                                        extra={intlMessage("verification-code-help")}
                                                        rules={[
                                                            {required: true, message: intlMessage("common.value-is-required")},
                                                            {validator: serverViolationsHolder.createServerValidator('CUSTOM'), message: intlMessage("common.value-is-not-valid")},
                                                        ]}>
                                                        <Input autoFocus={true}/>
                                                    </Form.Item>
                                                }

                                                {authenticator.type === AuthenticatorType.MOBILE && renderMobileFormElements()}
                                                {authenticator.type === AuthenticatorType.YUBIKEY && renderYubiKeyFormElements()}
                                                {authenticator.type === AuthenticatorType.FIDO && renderFidoFormElements()}
                                            </Form>
                                        </>
                                    }
                                </Spin>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        </DocumentTitle>
    )

    function renderMobileFormElements() {
        return (
            <>
                <BrowserView>
                    <img src={`/api/user/authenticators/${authenticator?.id}/invitation-qr-code`}/>
                </BrowserView>
                <MobileView>
                    <a href={authenticator?.registerUrl} className={"ant-btn ant-btn-primary"}>{intlMessage("register-button")}</a>
                </MobileView>

                <div className={styles.stores}>
                    <p>{intlMessage("register-authenticator.mobile-stores-help")}</p>

                    <a href={`https://play.google.com/store/apps/details?id=cz.sonpo.element2.mobile&hl=${appContext.language}`}><img src={`/img/s-play-${appContext.language}.png`}/></a>
                    <a href={`https://apps.apple.com/cz/app/2element/id1561478893?l=${appContext.language}`}><img src={`/img/s-store-${appContext.language}.png`}/></a>
                </div>
            </>
        )
    }

    function renderYubiKeyFormElements() {
        return (
            <>
                <Form.Item
                    name={"yubiKeyOtp"}
                    label={intlMessage("yubi-key-otp")}
                    extra={intlMessage("yubi-key-otp-help")}
                    rules={[
                        {required: true, message: intlMessage("yubi-key-otp-required")},
                        {validator: serverViolationsHolder.createServerValidator('CUSTOM'), message: intlMessage("yubi-key-otp-not-valid")},
                        {validator: serverViolationsHolder.createServerValidator('UNIQUE'), message: intlMessage("yubi-key-otp-already-registered")}
                    ]}>
                    <Input autoFocus={true}/>
                </Form.Item>

                <Form.Item {...tailFormLayout} className={appStyles.formButtons}>
                    <Button type={"primary"} htmlType={"submit"}>{intlMessage('add-authenticator')}</Button>
                </Form.Item>
            </>
        )
    }

    function renderFidoFormElements() {
        return (
            <>
                {renderAuthenticatorProfile(form, serverViolationsHolder, intlMessage, authenticator?.type!)}

                <Form.Item name={"discoverableCredential"}
                           label={intlMessage("authenticator.fido-discoverable-credential")}
                           extra={intlMessage("authenticator.fido-discoverable-credential-help")}
                           initialValue={true}
                           hidden={profile !== AuthenticatorProfile.GENERAL}
                           valuePropName={"checked"}>
                    <Switch onChange={onDiscoverableCredentialChanged} disabled={profile !== AuthenticatorProfile.GENERAL}/>
                </Form.Item>

                <Form.Item name={"userVerificationRequired"}
                           label={intlMessage("authenticator.fido-user-verification-required")}
                           extra={intlMessage("authenticator.fido-user-verification-required-help")}
                           initialValue={true}
                           hidden={profile !== AuthenticatorProfile.GENERAL}
                           valuePropName={"checked"}>
                    <Switch disabled={discoverableCredential || profile !== AuthenticatorProfile.GENERAL}/>
                </Form.Item>

                <Form.Item {...tailFormLayout} className={appStyles.formButtons}>
                    <Button type={"primary"} htmlType={"submit"}>{intlMessage('add-authenticator')}</Button>
                </Form.Item>
            </>
        )
    }

    function onDiscoverableCredentialChanged(checked: boolean) {
        if (checked) {
            form.setFieldsValue({userVerificationRequired: true});
        }
    }

    function onChangeProfile(value: AuthenticatorProfile) {
        form.setFieldsValue(deriveAttributesForAuthenticatorProfile(value, form.getFieldsValue()));
    }

    function onFinishRegistration(values: any) {
        const registerFunction = () => {
            authenticatorService.activateAuthenticator(authenticator?.id!, values)
                .then(
                    () => {
                        message.info(intlMessage("authenticator-registration-finished"));

                        setRegistrationState(RegistrationFormState.DONE);
                    },
                    reason => {
                        if (reason.response.status === 410) {
                            Modal.error({
                                title: intlMessage("verification-code-permanent-failure-modal-title"),
                                content: <>
                                    {intlMessage("verification-code-permanent-failure-modal-text")}
                                </>,
                                onOk: () => setAuthenticator(undefined)
                            });

                            return Promise.reject(reason);
                        }

                        if (reason && reason.response && reason.response.data && ServerConstraintViolationsHolder.containsConstraintViolations(reason.response.data)) {
                            serverViolationsHolder.violations = reason.response.data;

                            form.validateFields();
                        }

                        return Promise.reject(reason);
                    });
        };

        setInProgress(true);

        if (authenticator?.type === AuthenticatorType.YUBIKEY) {
            registerFunction();
        } else if (authenticator?.type === AuthenticatorType.FIDO) {
            fidoService.startInvitationRegistration(authenticator.friendlyInvitationId!, values.discoverableCredential, values.userVerificationRequired).then(response => {
                return createExtended(response.publicKeyCredentialCreationOptions)
                    .then(credentials => {
                        values.fidoChallengeId = response.fidoChallengeId;
                        values.attestationResponse = JSON.stringify(credentials);
                        values.authenticatorAttachment = credentials.authenticatorAttachment;

                        registerFunction();
                    })
                    .catch((reason) => {
                        if (reason.name === 'InvalidStateError') {
                            message.error(intlMessage("authenticator.fido-token-already-registered"), 5);
                        } else {
                            message.error(intlMessage("authenticator.fido-token-not-supported"));
                        }
                    })
                    .finally(() => setInProgress(false));
            })
        }
    }

}

export default RegisterAuthenticator;

class AuditLog {
    constructor(
        public id?: string,
        public moduleName?: string,
        public type?: string,
        public severity?: string,
        public timestamp?: Date,
        public principalId?: string,
        public principalText?: string,
        public targetId?: string,
        public targetText?: string,
        public attributes?: any,
        public changeLog?: any,
        public message?: any) {
        //
    }

    public static fromPOJO(data: any) {
        const auditLog = new AuditLog();

        auditLog.id = data.id;
        auditLog.moduleName = data.name;
        auditLog.type = data.type;
        auditLog.severity = data.severity;
        auditLog.timestamp = new Date(data.timestamp);
        auditLog.principalId = data.principalId;
        auditLog.principalText = data.principalText;
        auditLog.targetId = data.targetId;
        auditLog.targetText = data.targetText;
        auditLog.attributes = data.attributes;
        auditLog.changeLog = data.changeLog;
        auditLog.message = data.message;

        return auditLog;
    }
}

export default AuditLog;

import styles from "./ActiveSessionList.module.css";
import React, {useContext, useState} from "react";
import {AppContextContext, SessionServiceContext} from "../../Contexts";
import {useIntlMessage} from "../../createIntlMessage";
import {useTableHandler} from "../../sal-ui/TableHandler";
import PagedResult from "../../service/PagedResult";
import {DocumentTitle} from "../DocumentTitle";
import {Breadcrumb, Button, Card, List, message, Modal, Spin, Table} from "antd";
import {DeleteFilled, ReloadOutlined} from "@ant-design/icons";
import appStyles from "../../App.module.css";
import Column from "antd/es/table/Column";
import Session from "../../domain/Session";
import {formatDateTime} from "../../utils/FormatUtils";
import {SessionType} from "../../domain/SessionType";
import UAParser from "ua-parser-js";
import {renderIconForBrowser, renderIconForOs, UserAgentItem} from "./UserAgentItem";
import Flag from "../flag/Flag";

function ActiveSessionList() {
    const persistentIdent = 'ActiveSessionList';
    const appContext = useContext(AppContextContext);
    const sessionService = useContext(SessionServiceContext);
    const intlMessage = useIntlMessage("active-session-list");
    const tableHandler = useTableHandler("createdAt desc", {reloadFunction: reload, persistentIdent});
    const [sessions, setSessions] = useState<PagedResult<Session>>();

    function ActiveSessionCard({session}: { session: Session }) {
        const intlMessage = useIntlMessage("active-session-list");

        return (
            <Card size={"small"} title={(session.type === SessionType.SSO ? intlMessage("common.active-sessions-sso") : intlMessage("common.active-sessions-user-portal"))} extra={renderAction(session, "small")}>
                <div className={styles.listItem}>
                    <div><b>{intlMessage("common.created-at")}</b></div>
                    <div>{formatDateTime(session.createdAt)}</div>
                </div>

                <div className={styles.listItem}>
                    <div><b>{intlMessage("common.ip-address")}</b></div>
                    <div>{renderIpAddress(session.ipAddress, session)}</div>
                </div>

                <div className={styles.listItem}>
                    <div><b>{intlMessage("common.last-activity")}</b></div>
                    <div>{formatDateTime(session.lastActivity)}</div>
                </div>

                <div className={styles.listItem}>
                    <div><b>{intlMessage("common.expires-at")}</b></div>
                    <div>{formatDateTime(session.expiresAt)}</div>
                </div>

                <div className={styles.listItem}>
                    <div><b>{intlMessage("common.user-agent")}</b></div>
                    <div>{renderUserAgent(session.userAgent, session)}</div>
                </div>
            </Card>
        )
    }

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage("common.active-sessions")}`}>
            <>
                <Breadcrumb className={"common__breadcrumb"}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{intlMessage("common.active-sessions")}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{intlMessage("common.active-sessions")}</h1>

                <div className={appStyles.topButtonBar}>
                    <Button icon={<ReloadOutlined/>} onClick={tableHandler.reload} className={appStyles.btnSeamless}/>
                </div>

                {appContext.isTabletOrMobile &&
                    <>
                        {appContext.isTabletOrMobile && renderList(SessionType.SSO)}

                        {appContext.isTabletOrMobile && renderList(SessionType.USER)}
                    </>}

                {!appContext.isTabletOrMobile &&
                    <>
                        <h2>{intlMessage("common.active-sessions-sso")}</h2>

                        {!appContext.isTabletOrMobile && renderTable(SessionType.SSO)}

                        <div className={styles.spacer}/>

                        <h2>{intlMessage("common.active-sessions-user-portal")}</h2>

                        {!appContext.isTabletOrMobile && renderTable(SessionType.USER)}
                    </>}

            </>
        </DocumentTitle>
    )

    function renderTable(type: SessionType) {
        return (
            <Table className={styles.table}
                   showSorterTooltip={false}
                   loading={tableHandler.loading}
                   dataSource={sessions?.data.filter((session: Session) => session.type === type)}
                   size="middle"
                   pagination={false}
                   rowKey="id">
                <Column dataIndex="createdAt" title={intlMessage("common.created-at")} width={160} render={value => formatDateTime(value)}/>
                <Column dataIndex="ipAddress" title={intlMessage("common.ip-address")} render={renderIpAddress}/>
                <Column dataIndex="lastActivity" title={intlMessage("common.last-activity")} width={160} render={value => formatDateTime(value)}/>
                <Column dataIndex="expiresAt" title={intlMessage("common.expires-at")} width={160} render={value => formatDateTime(value)}/>
                <Column dataIndex="userAgent" title={intlMessage("common.user-agent")} render={renderUserAgent}/>
                <Column title={intlMessage("common.action")} width='100px' render={(value, record: Session) => renderAction(record, "middle")} className={"table-actions"}/>
            </Table>
        )
    }

    function renderList(type: SessionType) {
        return (
            <Spin spinning={tableHandler.loading}>
                <List dataSource={sessions?.data.filter((session: Session) => session.type === type)}
                      renderItem={(session: Session) => (
                          <List.Item className={styles.listItem}>
                              <ActiveSessionCard session={session}/>
                          </List.Item>
                      )}
                />
            </Spin>
        )
    }

    function renderIpAddress(value: any, session: Session) {
        return <Flag cca2={session.geoIdent}>{session.ipAddress}</Flag>;
    }

    function renderUserAgent(value: any, session: Session) {
        const parser = new UAParser(value);

        return (
            <div title={value}>
                <UserAgentItem left={renderIconForOs(parser.getOS())}
                               center={parser.getOS().name}
                               right={parser.getOS().version}
                />

                <UserAgentItem left={renderIconForBrowser(parser.getBrowser())}
                               center={parser.getBrowser().name}
                               right={parser.getBrowser().version}
                />
            </div>
        );
    }

    function renderAction(session: Session, buttonSize: "small" | "middle") {
        return (
            <>
                <Button className={appStyles.btnSeamless}
                        icon={<DeleteFilled/>}
                        size={buttonSize}
                        title={intlMessage("common.delete")}
                        onClick={() => {
                            Modal.confirm({
                                content: session.type === SessionType.SSO ? intlMessage("confirm-delete-sso") : intlMessage("confirm-delete"),
                                okText: intlMessage("common.delete"),
                                cancelText: intlMessage("common.cancel"),
                                okButtonProps: {danger: true},
                                onOk: () => onDeleteConfirm(session)
                            });
                        }}
                />
            </>
        )
    }

    function onDeleteConfirm(session: Session) {
        sessionService.delete(session.id!)
            .then(() => {
                message.success(intlMessage("deleted"));

                reload();
            });
    }

    function reload() {
        return sessionService.getList(tableHandler.queryOptions).then(value => {
            tableHandler.updateTotal(value.total);

            setSessions(value);
        });
    }

}

export default ActiveSessionList;
import {DeleteFilled, ReloadOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Card, Descriptions, List, message, Modal, Spin, Switch, Table} from "antd";
import Column from "antd/es/table/Column";
import React, {useContext, useEffect, useState} from "react";
import {DocumentTitle} from "../DocumentTitle";
import {useNavigate} from "react-router";
import {AppContextContext, AuthenticatorAssignmentServiceContext, AuthenticatorServiceContext} from "../../Contexts";
import {useIntlMessage} from "../../createIntlMessage";
import Authenticator from "../../domain/Authenticator";
import authenticator from "../../domain/Authenticator";
import {useTableHandler} from "../../sal-ui/TableHandler";
import {Link} from "react-router-dom";
import AuthenticatorAssignment from "../../domain/AuthenticatorAssignment";
import appStyles from "../../App.module.css";
import styles from "./AuthenticatorList.module.css";
import {AuthenticatorType} from "../../domain/AuthenticatorType";

function AuthenticatorList() {
    const appContext = useContext(AppContextContext);
    const authenticatorService = useContext(AuthenticatorServiceContext);
    const authenticatorAssignmentService = useContext(AuthenticatorAssignmentServiceContext);
    const intlMessage = useIntlMessage("authenticator-list");
    const navigate = useNavigate();
    const authenticatorAssignmentsTableHandler = useTableHandler("", {reloadFunction: loadAuthenticatorAssignments, persistentIdent: "AuthenticatorList"});
    const [authenticatorAssignments, setAuthenticatorAssignments] = useState<AuthenticatorAssignment[]>();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();
    }, [])

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage('common.authenticators')}`}>
            <div className={"detail-view"}>
                <Breadcrumb className={"common__breadcrumb"}>
                    <Breadcrumb.Item><Link to={"/"}>{appContext.config?.appName}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{intlMessage('common.authenticators')}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{intlMessage("common.authenticators")}</h1>

                <div className={appStyles.topButtonBar}>
                    <Button icon={<ReloadOutlined/>} onClick={authenticatorAssignmentsTableHandler.reload} className={appStyles.btnSeamless}/>

                    <Button type={"primary"} onClick={() => navigate(`/add-authenticator`)}>
                        {intlMessage("common.add")}
                    </Button>
                </div>

                <Spin spinning={authenticatorAssignments === undefined}>
                    {
                        authenticatorAssignments && authenticatorAssignments.length === 0 &&

                        <p>{intlMessage("common.no-authenticators")}</p>
                    }

                    {authenticatorAssignments && authenticatorAssignments.length > 0 && appContext.isTabletOrMobile && renderList()}

                    {authenticatorAssignments && authenticatorAssignments.length > 0 && !appContext.isTabletOrMobile && renderTable()}
                </Spin>
            </div>
        </DocumentTitle>
    )

    function loadAuthenticatorAssignments() {
        return authenticatorAssignmentService.getAssignments().then(value => setAuthenticatorAssignments(value.data));
    }

    function renderTable() {
        return (
            <Table className={styles.table}
                   showSorterTooltip={false}
                   dataSource={authenticatorAssignments}
                   size="middle"
                   onChange={authenticatorAssignmentsTableHandler.onTableChange}
                   onRow={onAuthenticatorAssignmentsRow}
                   pagination={authenticatorAssignmentsTableHandler.pagination}
                   rowKey="id"
                   loading={authenticatorAssignmentsTableHandler.loading}>
                <Column dataIndex={['authenticator', 'name']} title={intlMessage("common.name")} render={renderAuthenticatorName}/>
                <Column dataIndex={['authenticator', 'state']} title={intlMessage("common.state")} render={(state) => Authenticator.formatState(intlMessage, state)}/>
                <Column dataIndex={['authenticator', 'type']} title={intlMessage("common.type")} render={(type) => Authenticator.formatType(intlMessage, type)}/>
                <Column dataIndex={['authenticator', 'deviceInfo']} title={intlMessage("common.device-info")} render={renderDeviceInfo}/>
                <Column dataIndex="automaticMobilePush" align={"center"} title={intlMessage("common.automatic-mobile-push")} render={(value, record: AuthenticatorAssignment) => renderBooleanAttribute("automaticMobilePush", value, record)}/>
                <Column dataIndex="automaticSms" align={"center"} title={intlMessage("common.automatic-sms")} render={(value, record: AuthenticatorAssignment) => renderBooleanAttribute("automaticSms", value, record)}/>
                <Column title={intlMessage("common.action")} width='100px' render={renderAction} className={"table-actions"}/>
            </Table>
        );
    }

    function renderList() {
        return (
            <List dataSource={authenticatorAssignments}
                  renderItem={(item: AuthenticatorAssignment) => (
                      <List.Item className={styles['list-item']}>
                          <Card
                              title={renderAuthenticatorName(item.authenticator?.name, item)}
                              bordered={true}
                              size={"small"}
                              extra={Authenticator.formatState(intlMessage, item.authenticator?.state)}>
                              <Descriptions layout="vertical">
                                  <Descriptions.Item label={intlMessage("common.type")}>{Authenticator.formatType(intlMessage, item.authenticator?.type)}</Descriptions.Item>
                                  {item.authenticator?.deviceInfo && <Descriptions.Item label={intlMessage("authenticator-detail.device-model")}>{item.authenticator?.deviceModel}</Descriptions.Item>}
                                  {
                                      item.authenticator?.type === AuthenticatorType.MOBILE &&

                                      <>
                                          <Descriptions.Item label={intlMessage("common.automatic-mobile-push")}>{renderBooleanAttribute("automaticMobilePush", item.automaticMobilePush, item)}</Descriptions.Item>
                                          <Descriptions.Item label={intlMessage("common.automatic-sms")}>{renderBooleanAttribute("automaticSms", item.automaticSms, item)}</Descriptions.Item>
                                      </>
                                  }
                              </Descriptions>
                          </Card>
                      </List.Item>
                  )}></List>
        )
    }

    function renderAuthenticatorName(value: any, authenticatorAssignment: AuthenticatorAssignment) {
        return <Link to={`/authenticators/${authenticatorAssignment.authenticator!.id}`}>{value}</Link>;
    }

    function renderDeviceInfo(value: any, authenticatorAssignment: AuthenticatorAssignment) {
        return value;
    }

    function renderBooleanAttribute(attribute: "automaticMobilePush" | "automaticSms", value: any, assignment: AuthenticatorAssignment) {
        const onChange = (checked: boolean) => {
            authenticatorAssignmentsTableHandler.setLoading(true);

            assignment[attribute] = checked;

            authenticatorService.patch(assignment.authenticator?.id!, {[attribute]: checked})
                .then(() => {
                    loadAuthenticatorAssignments();

                    message.info((attribute === 'automaticMobilePush') ? intlMessage("authenticator.automatic-mobile-push-settings-changed") : intlMessage("authenticator.automatic-mobile-sms-settings-changed"));
                })
                .finally(() => {
                    authenticatorAssignmentsTableHandler.setLoading(false);
                })
        }

        return (
            <Switch checked={value} onChange={onChange} disabled={assignment.authenticator?.type !== 'MOBILE'}/>
        )
    }

    function renderAction(_value: any, assignment: AuthenticatorAssignment, _index: number) {
        return (
            <Button className={appStyles.btnSeamless}
                    title={intlMessage("common.delete")}
                    onClick={() => {
                        Modal.confirm({
                            content: intlMessage("confirm-authenticator-delete", {name: assignment.authenticator?.name}),
                            okText: intlMessage("common.delete"),
                            cancelText: intlMessage("common.cancel"),
                            okButtonProps: {danger: true},
                            onOk: () => onDeleteConfirm(assignment)
                        });
                    }}>
                <DeleteFilled/>
            </Button>
        )
    }

    function onDeleteConfirm(assignment: AuthenticatorAssignment) {
        authenticatorService.delete(assignment.authenticator!)
            .then(() => {
                message.success(intlMessage("authenticator.deleted", {name: assignment.authenticator!.name}));

                reload();
            });
    }

    function onAuthenticatorAssignmentsRow(assignment: AuthenticatorAssignment, index?: number) {
        return {onDoubleClick: () => navigate(`/authenticators/${assignment.authenticator?.id}`)}
    }

    function reload() {
        loadAuthenticatorAssignments();
    }

}

export default AuthenticatorList;
import 'mobx-react-lite/batchingForReactDom'
import {createRoot} from 'react-dom/client';
import React from 'react';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import {appContext, authService, preferenceService} from "./Contexts";
import './index.css';
import {loadMessages, updateLocaleResources} from "./Intl";
import AxiosService from "./service/AxiosService";
import {HelmetProvider} from "react-helmet-async";
import {AppConfig} from "./AppConfig";
import Axios from "axios";
import PreferenceNamespace from "./service/PreferenceNamespace";
import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/en";
import "dayjs/locale/cs";
import {register} from "./serviceWorker";
import {AuthenticatedAppConfig} from "./AuthenticatedAppConfig";
import "flag-icons/css/flag-icons.min.css";

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);

// nastavení Axios interceptorů

AxiosService.setUpInterceptors(appContext);

// registrace service workeru

register().then(registration => {
    appContext.serviceWorkerRegistration = registration;
})

Axios.get("/app-config").then(response => {
    appContext.config = AppConfig.fromPOJO(response.data);

    appContext.language = preferenceService.getItem(PreferenceNamespace.SettingsLanguage) || appContext.config.defaultLanguage || "en";

    updateLocaleResources(appContext.language);

    loadMessages().then(() => {
        authService.getLoggedInUser()
            .then(
                user => {
                    appContext.user = user;

                    return Axios.get("/authenticated-app-config").then(response => {
                        appContext.authenticatedConfig = AuthenticatedAppConfig.fromPOJO(response.data);
                    })
                },
                () => appContext.user = undefined)
            .finally(() => {
                const root = createRoot(document.getElementById('root')!)

                root.render(
                    <HelmetProvider>
                        <BrowserRouter basename={"/"}>
                            <App/>
                        </BrowserRouter>
                    </HelmetProvider>
                );
            })
    })
});

import Axios from "axios";
import Authenticator from "../domain/Authenticator";
import AuthenticatorAssignment from "../domain/AuthenticatorAssignment";

class AuthenticatorService {

    public add(authenticator: Authenticator): Promise<string> {
        return Axios.post(`/authenticators?type=${authenticator.type}`, authenticator).then(value => value.data.id)
    }

    public delete(authenticator: Authenticator): Promise<void> {
        return Axios.delete(`/authenticators/${authenticator.id}`);
    }

    public get(id: string): Promise<Authenticator> {
        return Axios.get(`/authenticators/${id}`)
            .then(value => Authenticator.fromPOJO(value.data))
    }

    public update(id: string, authenticator: Authenticator): Promise<void> {
        return Axios.put(`/authenticators/${id}`, authenticator);
    }

    public resetActivation(id: string, values: any): Promise<void> {
        return Axios.post(`/authenticators/${id}/activation`, values);
    }

    public randomName(): Promise<string> {
        return Axios.get(`/authenticators/random-name`).then(value => value.data);
    }

    public findByInvitationId(invitationId: string): Promise<Authenticator> {
        return Axios.get(`/authenticators/invitations/${invitationId}`)
            .then(value => Authenticator.fromPOJO(value.data))
    }

    public activateAuthenticator(id: string, values: any): Promise<void> {
        return Axios.put(`/authenticators/${id}/activation`, values);
    }

    public patch(id: string, data: AuthenticatorAssignment): Promise<void> {
        return Axios.patch(`/authenticators/${id}`, data);
    }

}

export default AuthenticatorService;

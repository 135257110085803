import {IntlMessageFormatter} from "../createIntlMessage";

export enum MultiFactorMethod {
    NONE = "NONE",
    MOBILE_PUSH = "MOBILE_PUSH",
    MOBILE_SMS = "MOBILE_SMS",
    MOBILE_OTP = "MOBILE_OTP",
    TLS_CERTIFICATE = "TLS_CERTIFICATE",
    FIDO = "FIDO",
    SSO = "SSO",
    ERROR = "ERROR"
}

export function formatMultiFactorMethod(intlMessage: IntlMessageFormatter, method?: string) {
    switch (method) {
        case "NONE":
            return intlMessage("multi-factor-method.NONE");
        case "MOBILE_PUSH":
            return intlMessage("multi-factor-method.MOBILE_PUSH");
        case "MOBILE_SMS":
            return intlMessage("multi-factor-method.MOBILE_SMS");
        case "MOBILE_OTP":
            return intlMessage("multi-factor-method.MOBILE_OTP");
        case "YUBIKEY_OTP":
            return intlMessage("multi-factor-method.YUBIKEY_OTP");
        case "TLS_CERTIFICATE":
            return intlMessage("multi-factor-method.TLS_CERTIFICATE");
        case "FIDO":
            return intlMessage("multi-factor-method.FIDO");
        case "SSO":
            return intlMessage("multi-factor-method.SSO");
        default:
            return "?";
    }
}

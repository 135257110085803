class AuthenticatorSetupStatus {
    constructor(
        public smsServiceStatus?: string,
        public yubiCloudServiceStatus?: string,
        public invitationLifetime?: number,
    ) {
        //
    }

    public static fromPOJO(data: any) {
        const status = new AuthenticatorSetupStatus();

        status.smsServiceStatus = data.smsServiceStatus;
        status.yubiCloudServiceStatus = data.yubiCloudServiceStatus;
        status.invitationLifetime = data.invitationLifetime;

        return status;
    }
}

export default AuthenticatorSetupStatus;

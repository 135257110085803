class UserPortalApplication {
    constructor(
        public name: string,
        public indexUrl: string,
        public localizedName?: any) {
        //
    }

    public static fromPOJO(data: any) {
        return new UserPortalApplication(
            data.name,
            data.indexUrl,
            data.localizedName
        );
    }
}

export default UserPortalApplication;
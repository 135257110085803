import React, {useContext, useEffect, useState} from "react";
import {AppContextContext, AuthRequestServiceContext} from "../../Contexts";
import AuthRequest from "../../domain/AuthRequest";
import {Breadcrumb, Button, Col, message, Row} from "antd";
import {DocumentTitle} from "../DocumentTitle";
import {useIntlMessage} from "../../createIntlMessage";
import {ReloadOutlined} from "@ant-design/icons";
import appStyles from "../../App.module.css";
import styles from "./AuthorizationRequestList.module.css";
import FidoAuthRequestDetail from "../FidoAuthRequestDetail";

const layout = {xs: 24, sm: 15, md: 14, lg: 10, xl: 7, xxl: 7};

function AuthorizationRequestList() {
    const appContext = useContext(AppContextContext);
    const authRequestService = useContext(AuthRequestServiceContext);
    const intlMessage = useIntlMessage("authorization-request-list");
    const [pendingRequests, setPendingRequests] = useState<AuthRequest[]>()

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();
    }, [])

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage("common.authorization-requests")}`}>
            <>
                <Breadcrumb className={"common__breadcrumb"}>
                    <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{intlMessage("common.authorization-requests")}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{intlMessage("common.authorization-requests")}</h1>

                <div className={appStyles.topButtonBar}>
                    <Button icon={<ReloadOutlined/>} onClick={reload} className={appStyles.btnSeamless}/>
                </div>

                {pendingRequests && pendingRequests.length == 0 && <>{intlMessage("no-pending-requests")}</>}

                {
                    pendingRequests && pendingRequests.length > 0 &&

                    <>
                        <Row gutter={16} justify={"start"}>
                            {pendingRequests.map(value => (
                                <Col {...layout} key={value.id}>
                                    <div className={styles.detail}>
                                        <FidoAuthRequestDetail authRequest={value} onAllowed={onAllowed} onExpired={onExpired}/>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </>
                }
            </>
        </DocumentTitle>
    )

    function onAllowed(authRequest: AuthRequest) {
        setPendingRequests(prevState => prevState?.filter(value => value.id !== authRequest.id));

        message.success(intlMessage("fido-auth-request-page.request-allowed-1"));
    }

    function onExpired(authRequest: AuthRequest) {
        setPendingRequests(prevState => prevState?.filter(value => value.id !== authRequest.id));
    }

    function reload() {
        authRequestService.getPending().then(value => setPendingRequests(value))
    }
}

export default AuthorizationRequestList;
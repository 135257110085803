import Axios from "axios";
import UserPortalApplication from "../domain/UserPortalApplication";

class UserPortalService {

    public getUserPortalApplications(): Promise<UserPortalApplication[]> {
        return Axios.get("/user-portal-applications").then(value => {
            return value.data.map((data: any) => UserPortalApplication.fromPOJO(data));
        })
    }

}

export default UserPortalService;
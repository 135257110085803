import * as React from 'react';


const IconUser = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
        <g transform="translate(-4 -4)" fill="none" fillRule="evenodd">
            <path
                d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                id="user_svg__a"
                fill="#FFF"
            />
        </g>
    </svg>
);

const IconAssignment = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 18 20" {...props}>
        <defs>
            <path
                d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"
                id="assignment_svg__a"
            />
        </defs>
        <g transform="translate(-3 -1)" fill="none" fillRule="evenodd">
            <mask id="assignment_svg__b" fill="#fff">
                <use xlinkHref="#assignment_svg__a"/>
            </mask>
            <g mask="url(#assignment_svg__b)" fill="#FFF">
                <path d="M0 0h24v24H0z"/>
            </g>
        </g>
    </svg>
);


const IconBuild = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
        <defs>
            <path
                d="M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z"
                id="build_svg__a"
                style={{fill: "#fff"}}
            />
        </defs>
        <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
            <mask id="build_svg__b" fill="#fff">
                <use xlinkHref="#build_svg__a"/>
            </mask>
            <g mask="url(#build_svg__b)" fill="#FFF">
                <path d="M0 0h24v24H0z"/>
            </g>
        </g>
    </svg>
);

const IconDoneAll = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 24 14" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M18 2L16.59.59l-6.34 6.34 1.41 1.41L18 2zM22.24.59L11.66 11.17 7.48 7 6.07 8.41 11.66 14l12-12L22.24.59zM.41 8.41L6 14l1.41-1.41L1.83 7 .41 8.41z"
                fill="#FFF"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

const IconBlock = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zM2 10c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L3.69 14.9A7.902 7.902 0 0 1 2 10zm8 8c-1.85 0-3.55-.63-4.9-1.69L16.31 5.1A7.902 7.902 0 0 1 18 10c0 4.42-3.58 8-8 8z"
                fill="#FFF"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

const IconArchive = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
        <defs fill="#fff">
            <path
                d="M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM12 17.5L6.5 12H10v-2h4v2h3.5L12 17.5zM5.12 5l.81-1h12l.94 1H5.12z"
                id="archive_svg__a"
                style={{fill: "#fff"}}
            />
        </defs>
        <g transform="translate(-3 -3)" fill="none" fillRule="nonzero">
            <mask id="archive_svg__b" fill="#fff">
                <use xlinkHref="#archive_svg__a"/>
            </mask>
            <g mask="url(#archive_svg__b)" >
                <path d="M0 0h24v24H0z" fill="#fff"/>
            </g>
        </g>
    </svg>
);

const IconTrash = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 14 18" {...props}>
        <defs>
            <path
                d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                id="delete_svg__a"
                style={{fill: "#fff"}}
            />
        </defs>
        <g transform="translate(-5 -3)" fill="none" fillRule="evenodd">
            <mask id="delete_svg__b" fill="#fff">
                <use xlinkHref="#delete_svg__a"/>
            </mask>
            <g mask="url(#delete_svg__b)" fill="#fff">
                <path d="M0 0h24v24H0z"/>
            </g>
        </g>
    </svg>
);


const IconDownload = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 14 17" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M14 6h-4V0H4v6H0l7 7 7-7zM0 15v2h14v-2H0z"
                fill="#F7F9FC"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

const IconUpload = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 14 17" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M4 13h6V7h4L7 0 0 7h4v6zm-4 2h14v2H0v-2z"
                fill="#F7F9FC"
                fillRule="nonzero"
            />
        </g>
    </svg>
);


const IconExit = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 18 18" {...props}>
        <defs>
            <path
                d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5a2 2 0 0 0-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
                id="exit_to_app_svg__a"
            />
        </defs>
        <g transform="translate(-3 -3)" fill="none" fillRule="evenodd">
            <mask id="exit_to_app_svg__b" fill="#fff">
                <use xlinkHref="#exit_to_app_svg__a"/>
            </mask>
            <g mask="url(#exit_to_app_svg__b)" fill="#434656">
                <path d="M0 0h24v24H0z"/>
            </g>
        </g>
    </svg>
);


const IconCloudUpload = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 80 53" {...props}>
        <defs>
            <path
                d="M64.5 20.008C62.233 8.579 52.133 0 40 0c-9.633 0-18 5.433-22.167 13.383C7.8 14.443 0 22.889 0 33.124 0 44.089 8.967 53 20 53h43.333C72.533 53 80 45.58 80 36.437c0-8.744-6.833-15.833-15.5-16.43zm-17.833 9.805v13.25H33.333v-13.25h-10L40 13.25l16.667 16.563h-10z"
                id="ic_cloud_upload_24px_svg__a"
            />
        </defs>
        <g fill="none" fillRule="evenodd" opacity={0.18}>
            <mask id="ic_cloud_upload_24px_svg__b" fill="#fff">
                <use xlinkHref="#ic_cloud_upload_24px_svg__a"/>
            </mask>
            <g mask="url(#ic_cloud_upload_24px_svg__b)" fill="#0583FF">
                <path d="M0-13.25h80v79.5H0z"/>
            </g>
        </g>
    </svg>
);

const IconLock = (props: any) => (
    <svg width="1em" height="1em" viewBox="0 0 16 21" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M14 7h-1V5c0-2.76-2.24-5-5-5S3 2.24 3 5v2H2C.9 7 0 7.9 0 9v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H4.9V5c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
                fill="#A7AABD"
                fillRule="nonzero"
            />
        </g>
    </svg>

);

export {IconUser, IconAssignment, IconBuild, IconDoneAll, IconBlock, IconArchive, IconTrash, IconDownload, IconUpload, IconExit, IconCloudUpload, IconLock}


import {Button, message, Progress, Space} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {AuthRequestServiceContext, FidoServiceContext} from "../Contexts";
import {useIntlMessage} from "../createIntlMessage";
import styles from "./FidoAuthRequestDetail.module.css";
import AuthRequest from "../domain/AuthRequest";
import AuthRequestType from "../domain/AuthRequestType";
import {getExtended} from "@github/webauthn-json/extended";
import useInterval from "./common/useInterval";

interface IProps {
    authRequest: AuthRequest;

    onAllowed?: (...args: any[]) => any;

    onExpired?: (...args: any[]) => any;
}

function FidoAuthRequestDetail({authRequest, onAllowed, onExpired}: IProps) {
    const authRequestService = useContext(AuthRequestServiceContext);
    const fidoService = useContext(FidoServiceContext);
    const intlMessage = useIntlMessage("fido-auth-request-page");
    const [inProgress, setInProgress] = useState(false);
    const [remainingSeconds, setRemainingSeconds] = useState<number>();

    useEffect(() => {
        setRemainingSeconds(authRequest.expiresInSeconds!);

        // updateTimeLeft();
    }, [authRequest]);

    useInterval(updateTimeLeft, 1000);

    return (
        <div>
            {
                authRequest?.type === AuthRequestType.LOGIN &&

                <>
                    <h1>
                        {intlMessage("title-login")} <br/>
                        <b>{authRequest.application}</b>
                    </h1>

                    <div className={styles.explanation}>{intlMessage("explanation-action")}</div>

                    <div className={styles.attribute}>
                        <h3>{intlMessage("attribute-username")}:</h3>

                        <div>{authRequest.username}</div>
                    </div>

                    {
                        authRequest.userIp &&

                        <div className={styles.attribute}>
                            <h3>{intlMessage("attribute-ip-address")}</h3>

                            <div>{authRequest.userIp}{(authRequest.geoIdent) ? ` (${authRequest.geoIdent})` : ''}</div>
                        </div>
                    }

                </>
            }

            {
                authRequest?.type === AuthRequestType.ACTION_CONFIRMATION &&

                <>
                    <h1>{intlMessage("title-action-confirmation")}</h1>

                    <h1>{authRequest.actionName}</h1>
                </>
            }

            <div className={styles.explanation}>{intlMessage("explanation-warning")}</div>

            <Space direction={"horizontal"}>
                <Button className={"ant-btn-success"}
                        size={"large"}
                        type={"primary"}
                        disabled={inProgress}
                        onClick={onAllow}>
                    {intlMessage("allow")}
                </Button>

                <Button size={"large"}
                        disabled={true}
                        type={"dashed"}
                        onClick={onIgnore}>
                    {intlMessage("ignore")}
                </Button>
            </Space>

            {
                remainingSeconds !== undefined &&

                <Progress className={styles.progress}
                          percent={authRequest?.percentOfTimeRemaining(remainingSeconds)}
                          width={38}
                          style={{fontSize: '10px'}}
                          strokeColor={{from: "#108ee9", to: "#108ee9"}}
                          format={() => remainingSeconds! > 0 ? `${remainingSeconds} s` : ""}
                />
            }
        </div>
    )

    function updateTimeLeft() {
        if (authRequest && remainingSeconds !== undefined && remainingSeconds !== 0) {
            const newValue = remainingSeconds - 1;

            setRemainingSeconds(newValue)

            if (newValue === 0) {
                onExpired?.(authRequest);
            }
        }
    }

    function onAllow() {
        setInProgress(true);

        fidoService.startAssertionForAuthRequest(authRequest.id!)
            .then(response => {
                getExtended(response.publicKeyCredentialRequestOptions)
                    .then(result => {
                        return authRequestService.allow(authRequest.id!, response.fidoChallengeId, JSON.stringify(result))
                            .then(() => {
                                onAllowed?.(authRequest);
                            })
                    })
                    .catch(() => {
                        message.error(intlMessage("allow-request-failed"));
                    })
            })
            .catch(() => {
                onExpired?.(authRequest);
            })
            .finally(() => setInProgress(false));
    }

    function onIgnore() {
        //
    }

}

export default FidoAuthRequestDetail;
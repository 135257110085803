class WebPushSubscription {
    constructor(
        public id?: string,
        public name?: string,
        public endpoint?: string) {
        //
    }

    public static fromPOJO(data: any) {
        const subscription = new WebPushSubscription();

        subscription.id = data.id;
        subscription.name = data.name;
        subscription.endpoint = data.endpoint;

        return subscription;
    }
}

export default WebPushSubscription;
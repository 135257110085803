import {Breadcrumb, Button, Form, message} from "antd";
import {useForm} from "antd/es/form/Form";
import Password from "antd/es/input/Password";
import React, {useContext} from "react";
import {DocumentTitle} from "./DocumentTitle";
import {useNavigate} from "react-router";
import {AppContextContext, AuthServiceContext} from "../Contexts";
import {useIntlMessage} from "../createIntlMessage";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";
import appStyles from "../App.module.css";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function ChangePassword() {
    const appContext = useContext(AppContextContext);
    const authService = useContext(AuthServiceContext);
    const navigate = useNavigate();
    const intlMessage = useIntlMessage("profile.change-password");
    const [form] = useForm();

    const layout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage('title')}`}>
            <Breadcrumb className={"common__breadcrumb"}>
                <Breadcrumb.Item>{appContext.config?.appName}</Breadcrumb.Item>
                <Breadcrumb.Item>{intlMessage('title')}</Breadcrumb.Item>
            </Breadcrumb>

            <h1>{intlMessage('common.password-change')}</h1>

            <Form {...layout} form={form} className={appStyles.formHorizontal} onFinish={onFinish}>
                <Form.Item
                    name={"currentPassword"}
                    label={intlMessage("common.current-password")}
                    rules={[{required: true, message: intlMessage("common.value-is-required")},
                        {
                            validator: serverViolationsHolder.createServerValidator('CUSTOM'),
                            message: intlMessage("current-password-is-invalid")
                        }
                    ]}>
                    <Password autoFocus={true}/>
                </Form.Item>
                <Form.Item
                    name={"password"}
                    label={intlMessage("common.new-password")}
                    rules={[{required: true, message: intlMessage("common.value-is-required")},
                        {
                            validator: serverViolationsHolder.createServerValidator('CUSTOM'),
                            message: serverViolationsHolder.violations.constraintViolations.password?.CUSTOM.message
                        }
                    ]}>
                    <Password/>
                </Form.Item>

                <Form.Item {...tailLayout} className={appStyles.formButtons}>
                    <Button type={"primary"} htmlType={"submit"}>{intlMessage('common.change-password')}</Button>
                </Form.Item>
            </Form>
        </DocumentTitle>
    )

    function onFinish(values: any) {
        authService.changePassword(values.currentPassword, values.password)
            .then(
                (id) => {
                    message.success(intlMessage('password-changed'));

                    navigate(-1);
                },
                error => serverViolationsHolder.handleServerError(error, form)
            );

    }
}

export default ChangePassword;

import Axios from "axios";
import AuditLog from "../domain/AuditLog";
import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";

class AuditLogService {

    public getListForAuthenticator(authenticatorId: number, queryOptions: QueryOptions): Promise<PagedResult<AuditLog>> {
        return Axios.get(`/authenticators/${authenticatorId}/audit-logs?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: AuditLog[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(AuditLog.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public getListForUser(queryOptions: QueryOptions): Promise<PagedResult<AuditLog>> {
        return Axios.get(`/audit-logs?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: AuditLog[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(AuditLog.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public getListForUserFailures(queryOptions: QueryOptions): Promise<PagedResult<AuditLog>> {
        return Axios.get(`/audit-logs/failures?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: AuditLog[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(AuditLog.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

}

export default AuditLogService;

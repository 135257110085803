import {InputNumber, Select} from "antd";
import * as React from "react";
import {useEffect, useState} from "react";
import {useIntlMessage} from "../../createIntlMessage";

interface IProps {
    value?: number;
    onChange?: (value: number | null) => void;
    disabled?: boolean;
}

type TimeUnit = "minute" | "hour" | "day";

function TimeUnitInput({value, onChange, disabled}: IProps) {
    const MAX_SIZE = 2147483640;

    const [timeUnit, setTimeUnit] = useState<TimeUnit>("minute");
    const [localValue, setLocalValue] = useState<number>();
    const intlMessage = useIntlMessage("");

    useEffect(() => {
        if (value !== undefined && value != null) {
            const timeAndUnit = reduceSecondsByTimeUnit(value);

            setTimeUnit(timeAndUnit.unit);

            setLocalValue(timeAndUnit.value);
        } else {
            setTimeUnit("minute");

            setLocalValue(undefined);
        }
    }, [value]);

    return (
        <InputNumber min={1}
                     max={1000000000}
                     style={{width: 200}}
                     disabled={disabled}
                     value={localValue}
                     onChange={value => {
                         if (value !== undefined && value !== null) {
                             onChange?.(Math.min(expandSecondsForTimeUnit(timeUnit, value), MAX_SIZE))
                         } else {
                             onChange?.(null);
                         }
                     }}
                     addonAfter={
                         <Select style={{width: 100}}
                                 disabled={disabled}
                                 onChange={unit => {
                                     setTimeUnit(unit);

                                     if (localValue !== undefined) {
                                         onChange?.(Math.min(expandSecondsForTimeUnit(unit, localValue), MAX_SIZE));
                                     }
                                 }}
                                 value={timeUnit}>
                             <Select.Option value={"minute"}>{intlMessage("common.time-unit-minute")}</Select.Option>
                             <Select.Option value={"hour"}>{intlMessage("common.time-unit-hour")}</Select.Option>
                             <Select.Option value={"day"}>{intlMessage("common.time-unit-day")}</Select.Option>
                         </Select>
                     }
        />
    )
}

function reduceSecondsByTimeUnit(value: number): { unit: TimeUnit, value: number } {
    if (value % 86400 === 0) {
        return {unit: "day", value: value / 86400};
    } else if (value % 3600 === 0) {
        return {unit: "hour", value: value / 3600};
    } else {
        return {unit: "minute", value: value / 60};
    }
}

function expandSecondsForTimeUnit(unit: TimeUnit, value: number) {
    if (unit === 'minute') {
        return value * 60;
    } else if (unit === 'hour') {
        return value * 3600;
    } else if (unit === 'day') {
        return value * 86400;
    } else {
        return value;
    }
}

export default TimeUnitInput;
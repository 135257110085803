import {CheckOutlined, CloseOutlined, CopyOutlined, LoadingOutlined, QrcodeOutlined} from "@ant-design/icons";
import QRCode from "react-qr-code";
import Collapse from "@kunukn/react-collapse/dist/Collapse.umd";
import {Breadcrumb, Button, Descriptions, Form, Input, message, Modal, Space, Spin, Switch} from "antd";
import {useForm, useWatch} from "antd/es/form/Form";
import dayjs from "dayjs";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DocumentTitle} from "../DocumentTitle";
import {useNavigate, useParams} from "react-router";
import {Link} from "react-router-dom";
import {AppContextContext, AuditLogServiceContext, AuthenticatorServiceContext, AuthenticatorSetupStatusServiceContext, ClientNotificationServiceContext, FidoServiceContext} from "../../Contexts";
import {useIntlMessage} from "../../createIntlMessage";
import Authenticator from "../../domain/Authenticator";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import {ObjectAuditLogList, ObjectAuditLogListDelegate} from "../auditlog/ObjectAuditLogList";
import QueryOptions from "../../sal-ui/QueryOptions";
import styles from "./AuthenticatorDetail.module.css";
import appStyles from "../../App.module.css";
import {AuthenticatorType} from "../../domain/AuthenticatorType";
import {createExtended} from "@github/webauthn-json/extended";
import useActionConfirmation from "../common/useActionConfirmation";
import {formatFidoAuthenticatorAttachment} from "../../domain/FidoAuthenticatorAttachment";
import {formatDateTime} from "../../utils/FormatUtils";
import {readFileAsText} from "../../utils/PromiseUtils";
import {AuthenticatorProfile, suggestProfile} from "../../domain/AuthenticatorProfile";
import {renderActivationFidoElements, renderActivationTlsCertificateElements, renderActivationYubiKeyElements, renderAuthenticatorProfile, renderEnrollment} from "./AuthenticatorFormHelper";
import EnrollmentType from "../../domain/EnrollmentType";
import InvitationVerificationCodeEnrollment from "../../domain/InvitationVerificationCodeEnrollment";
import AuthenticatorSetupStatus from "../../service/AuthenticatorSetupStatus";
import CopyToClipboard from "react-copy-to-clipboard";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function AuthenticatorDetail() {
    const appContext = useContext(AppContextContext);
    const auditLogService = useContext(AuditLogServiceContext);
    const authenticatorService = useContext(AuthenticatorServiceContext);
    const clientNotificationService = useContext(ClientNotificationServiceContext);
    const fidoService = useContext(FidoServiceContext);
    const setupStatusService = useContext(AuthenticatorSetupStatusServiceContext);
    const intlMessage = useIntlMessage("authenticator-detail");
    const navigate = useNavigate();
    const {authenticatorId}: any = useParams();
    const [authenticator, setAuthenticator] = useState<Authenticator>();
    const [resetActivationForm] = useForm();
    const [resetActivationInProgress, setResetActivationInProgress] = useState(false);
    const [form] = useForm();
    const [editMode, setEditMode] = useState(false);
    const discoverableCredential = useWatch<boolean>("discoverableCredential", resetActivationForm);
    const [resetRegistrationMode, setResetRegistrationMode] = useState(false);
    const [lastReload, setLastReload] = useState(Date.now());
    const auditLogsRef = useRef<ObjectAuditLogListDelegate>(null);
    const [actionRequiredErrorHandler] = useActionConfirmation();
    const profile = useWatch<AuthenticatorProfile>("profile", resetActivationForm);
    const enrollment = useWatch<EnrollmentType>(["basicAttributes", "enrollment", "type"], resetActivationForm);
    const [setupStatus, setSetupStatus] = useState<AuthenticatorSetupStatus>();
    const verificationCodeEnrollment = useWatch<InvitationVerificationCodeEnrollment>(["basicAttributes", "verificationCodeEnrollment", "type"], resetActivationForm);

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    const tailLayout = (appContext.isTabletOrMobile)
        ? {wrapperCol: {span: 24}}
        : {wrapperCol: {offset: 8, span: 16}};

    useEffect(() => resetActivationForm.setFieldValue(["basicAttributes", "verificationCodeEnrollment", "type"], InvitationVerificationCodeEnrollment.NONE), [enrollment]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setupStatusService.getSetupStatus().then(value => setSetupStatus(value));

        reload();

        clientNotificationService.subscribe("element2:AUTHENTICATOR_REGISTRATION_STATUS_CHANGED", () => reload(), authenticatorId)

        return function cleanup() {
            clientNotificationService.unsubscribe("element2:AUTHENTICATOR_REGISTRATION_STATUS_CHANGED", authenticatorId);
        }
    }, []);

    const detailBoxProps = (appContext.isTabletOrMobile)
        ? {
            className: `${appStyles.details} ${styles.detailBox}`,
            layout: "vertical" as "horizontal" | "vertical" | undefined,
            bordered: false
        }
        : {
            className: `${appStyles.details} ${styles.detail}`,
            labelStyle: {width: 250},
            bordered: true
        };

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage("title", {name: authenticator?.name, disableMarkup: true})}`}>
            <>
                <Breadcrumb className={"common__breadcrumb"}>
                    <Breadcrumb.Item><Link to={"/"}>{appContext.config?.appName}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/authenticators"}>{intlMessage("common.authenticators")}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{authenticator?.name}</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={!authenticator} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                    <h1>{intlMessage("title", {name: authenticator?.name})}</h1>
                    {
                        <>
                            <div className={appStyles.topButtonBar}>
                                <Space direction={"horizontal"}>
                                    <Button onClick={() => setEditMode(!editMode)}>{intlMessage("common.edit")}</Button>

                                    <Button onClick={() => setResetRegistrationMode(!resetRegistrationMode)}>{intlMessage('reset-registration')}</Button>

                                    <Button danger={true}
                                            onClick={() => {
                                                Modal.confirm({
                                                    content: intlMessage("confirm-authenticator-delete", {name: authenticator?.name}),
                                                    okText: intlMessage("common.delete"),
                                                    cancelText: intlMessage("common.cancel"),
                                                    okButtonProps: {danger: true},
                                                    onOk: () => onDeleteConfirm()
                                                });
                                            }}>
                                        {intlMessage('common.delete')}
                                    </Button>
                                </Space>
                            </div>

                            {renderEditForm()}

                            {renderResetActivationForm()}

                            {renderFinishActivationSection()}

                            <Descriptions column={1} size="small" {...detailBoxProps}>
                                <Descriptions.Item label={intlMessage("common.created-at")}>{authenticator && dayjs(authenticator?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                                <Descriptions.Item label={intlMessage("authenticator-add.type")}>{(authenticator) ? Authenticator.formatType(intlMessage, authenticator?.type) : ''}</Descriptions.Item>
                                <Descriptions.Item label={intlMessage("common.state")}>{(authenticator) ? Authenticator.formatState(intlMessage, authenticator?.state) : ''}</Descriptions.Item>
                                {
                                    authenticator?.state === 'INVITED' &&

                                    <>
                                        <Descriptions.Item label={intlMessage("invitation-expires-at")}>{dayjs(authenticator?.invitationExpiresAt).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("invitation-register-url")}>
                                            <a href={authenticator?.registerUrl} target={"_blank"}>{authenticator?.registerUrl}</a>

                                            <Space style={{marginLeft: 8}}>
                                                <CopyToClipboard text={authenticator?.registerUrl!} onCopy={(text) => message.info(intlMessage("common.clipboard-text-copied", {text}))}>
                                                    <Button className={appStyles.btnSeamless} size="small" icon={<CopyOutlined/>} title={intlMessage("common.clipboard-tool-tip")}/>
                                                </CopyToClipboard>

                                                <Button
                                                    size={"small"}
                                                    icon={<QrcodeOutlined/>}
                                                    className={appStyles.btnSeamless}
                                                    title={intlMessage("common.show-qr-code")}
                                                    onClick={() => {
                                                        Modal.info({
                                                            title: intlMessage("authenticator.qr-code-modal-title"),
                                                            icon: undefined,
                                                            content: <>
                                                                <p>{intlMessage("authenticator.qr-code-modal-text")}</p>

                                                                <div className={styles.qrCode}>
                                                                    <QRCode
                                                                        size={256}
                                                                        viewBox={`0 0 256 256`}
                                                                        value={authenticator?.registerUrl!}
                                                                    />
                                                                </div>
                                                            </>
                                                        });
                                                    }}
                                                />
                                            </Space>
                                        </Descriptions.Item>
                                    </>
                                }
                                {
                                    authenticator?.type === 'MOBILE' &&

                                    <>
                                        <Descriptions.Item label={intlMessage("common.phone-number")}>{authenticator.phoneNumber}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("sms-otp-codes-enabled")}>{(authenticator.smsOtpCodesEnabled) ? <CheckOutlined/> : <CloseOutlined/>}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("common.automatic-mobile-push")}>{(authenticator.automaticMobilePush) ? <CheckOutlined/> : <CloseOutlined/>}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("common.automatic-sms")}>{(authenticator.automaticSms) ? <CheckOutlined/> : <CloseOutlined/>}</Descriptions.Item>

                                        {
                                            authenticator?.state === 'REGISTERED' &&

                                            <>
                                                <Descriptions.Item label={intlMessage("device-model")}>{authenticator.deviceModel}</Descriptions.Item>
                                                <Descriptions.Item label={intlMessage("os-version")}>{authenticator.version}</Descriptions.Item>
                                                <Descriptions.Item label={intlMessage("public-key-fingerprint")}>{authenticator?.publicKeyFingerprint}</Descriptions.Item>
                                            </>
                                        }

                                    </>
                                }
                                {
                                    authenticator?.type === 'YUBIKEY' &&

                                    <Descriptions.Item label={intlMessage("yubi-key-public-id")}>{authenticator.publicId}</Descriptions.Item>
                                }
                                {
                                    authenticator?.type === 'FIDO' && authenticator?.state === 'REGISTERED' &&

                                    <>
                                        <Descriptions.Item label={intlMessage("authenticator.fido-attachment")}>{formatFidoAuthenticatorAttachment(intlMessage, authenticator.attachment)}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("device-model")}>{authenticator.deviceModel}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("version")}>{authenticator.version}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("authenticator.fido-discoverable-credential")}>{(authenticator.discoverable) ? <CheckOutlined/> : <CloseOutlined/>}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("authenticator.fido-user-verification-required")}>{(authenticator.userVerificationRequired) ? <CheckOutlined/> : <CloseOutlined/>}</Descriptions.Item>
                                    </>
                                }
                                {
                                    authenticator?.type === AuthenticatorType.TLS_CERTIFICATE &&

                                    <>
                                        <Descriptions.Item label={intlMessage("common.subject")}>{authenticator.certificate?.subject}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("settings-tls-certificates.not-before")}>{formatDateTime(authenticator.certificate?.notBefore)}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("settings-tls-certificates.not-after")}>{formatDateTime(authenticator.certificate?.notAfter)}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("common.issuer")}>{authenticator.certificate?.issuer}</Descriptions.Item>
                                        <Descriptions.Item label={intlMessage("tls-settings.tls-certificate-fingerprint")}>{authenticator.certificate?.fingerprint}</Descriptions.Item>
                                    </>
                                }
                            </Descriptions>

                            <h3>{intlMessage("common.audit-logs")}</h3>

                            <ObjectAuditLogList loadData={reloadAuditLogs} ref={auditLogsRef}/>
                        </>
                    }
                </Spin>
            </>
        </DocumentTitle>
    )

    function renderFinishActivationSection() {
        if (authenticator?.state === 'INVITED' && authenticator?.type === AuthenticatorType.MOBILE) {
            return (
                <>
                    <p>{intlMessage("invite-text")}</p>

                    <img alt={"QR"} src={`/api/user/authenticators/${authenticatorId!}/invitation-qr-code?` + lastReload} className={styles.qrcode}/>
                </>
            )
        }
    }

    function renderEditForm() {
        return (
            <Collapse isOpen={editMode}>
                <h3>{intlMessage("edit-authenticator-title")}</h3>

                <Form
                    {...layout}
                    form={form}
                    className={appStyles.formHorizontal}
                    initialValues={{
                        enrollment: {type: 'DIRECT'},
                        profile: suggestProfile()
                    }}
                    onFinish={onFinishEdit}>
                    <Form.Item
                        name={"name"}
                        label={intlMessage("common.name")}
                        rules={[{required: true, message: intlMessage("common.name-required")}]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    {
                        authenticator?.type === 'MOBILE' &&

                        <>
                            <Form.Item
                                name={"phoneNumber"}
                                label={intlMessage("common.phone-number")}
                                rules={[{required: false, message: intlMessage("common.phone-number-required")}]}>
                                <Input maxLength={100}/>
                            </Form.Item>

                            <Form.Item
                                name={"smsOtpCodesEnabled"}
                                label={intlMessage("sms-otp-codes-enabled")}
                                valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>

                            <Form.Item
                                name={"automaticMobilePush"}
                                label={intlMessage("common.automatic-mobile-push")}
                                valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>

                            <Form.Item
                                name={"automaticSms"}
                                label={intlMessage("common.automatic-sms")}
                                valuePropName={"checked"}>
                                <Switch/>
                            </Form.Item>
                        </>
                    }

                    <Form.Item {...tailLayout} className={appStyles.formButtons}>
                        <Button type={"primary"} htmlType={"submit"}>{intlMessage("common.save")}</Button>
                        <Button onClick={() => setEditMode(false)}>{intlMessage("common.cancel")}</Button>
                    </Form.Item>
                </Form>
            </Collapse>
        )
    }

    function renderResetActivationForm() {
        return (
            <Collapse isOpen={resetRegistrationMode}>
                <h3>{intlMessage("reset-registration-title")}</h3>

                <p>{intlMessage("reset-registration-intro")}</p>

                <Form
                    {...layout}
                    form={resetActivationForm}
                    className={appStyles.formHorizontal}
                    onFinish={onFinishResetRegistration}
                    initialValues={{
                        basicAttributes: {
                            enrollment: {type: 'DIRECT'},
                            verificationCodeEnrollment: {type: 'DIRECT'},
                        }
                    }}>
                    <div className={appStyles.formSectionTitle}>{intlMessage("common.section-basic")}</div>

                    {renderEnrollment(resetActivationForm, serverViolationsHolder, intlMessage, authenticator?.type!, enrollment, setupStatus!, verificationCodeEnrollment)}

                    {enrollment === EnrollmentType.DIRECT &&
                        <>
                            <div className={appStyles.formSectionTitle}>{intlMessage("authenticator.section-type-specific")}</div>

                            {renderAuthenticatorProfile(resetActivationForm, serverViolationsHolder, intlMessage, authenticator?.type!)}

                            {renderActivationFormElements()}
                        </>
                    }

                    <Form.Item {...tailLayout} className={appStyles.formButtons}>
                        <Button type={"primary"}
                                htmlType={"submit"}
                                loading={resetActivationInProgress}>
                            {intlMessage('reset-registration')}
                        </Button>

                        <Button disabled={resetActivationInProgress}
                                onClick={() => {
                                    setResetRegistrationMode(false);

                                    resetActivationForm.resetFields();
                                }}>
                            {intlMessage("common.cancel")}
                        </Button>
                    </Form.Item>
                </Form>
            </Collapse>
        );
    }

    function renderActivationFormElements() {
        if (authenticator?.type === AuthenticatorType.YUBIKEY) {
            return renderActivationYubiKeyElements(resetActivationForm, serverViolationsHolder, intlMessage);
        } else if (authenticator?.type === AuthenticatorType.FIDO) {
            return renderActivationFidoElements(resetActivationForm, serverViolationsHolder, intlMessage, authenticator?.type, enrollment, profile, discoverableCredential);
        } else if (authenticator?.type === AuthenticatorType.TLS_CERTIFICATE) {
            return renderActivationTlsCertificateElements(resetActivationForm, serverViolationsHolder, intlMessage);
        }
    }

    function onDeleteConfirm() {
        authenticatorService.delete(authenticator!)
            .then(() => {
                message.success(intlMessage("authenticator.deleted", {name: authenticator!.name}));

                navigate("/authenticators", {replace: true});
            })
            .catch(actionRequiredErrorHandler(onDeleteConfirm))
    }

    function onFinishResetRegistration(values: any) {
        const resetFunction = () => {
            setResetActivationInProgress(true);

            authenticatorService.resetActivation(authenticatorId!, values)
                .then(() => {
                    message.success(intlMessage("authenticator-registration-reset"));

                    setResetRegistrationMode(false);

                    resetActivationForm.resetFields();

                    reload();
                })
                .catch(reason => {
                        if (reason && reason.response && reason.response.data && ServerConstraintViolationsHolder.containsConstraintViolations(reason.response.data)) {
                            serverViolationsHolder.violations = reason.response.data;

                            resetActivationForm.validateFields();

                            if (reason.response.data.constraintViolations['registration']) {
                                message.error(intlMessage("authenticator.fido-token-not-supported"));
                            }
                        }

                        return Promise.reject(reason);
                    }
                )
                .finally(() => setResetActivationInProgress(false));
        }

        if (enrollment === EnrollmentType.DIRECT) {
            if (authenticator?.type === AuthenticatorType.FIDO) {
                setResetActivationInProgress(true);

                fidoService.startRegistration(authenticator.name!, values.discoverableCredential, values.userVerificationRequired, authenticatorId).then(response => {
                    createExtended(response.publicKeyCredentialCreationOptions)
                        .then(credentials => {
                            values.fidoChallengeId = response.fidoChallengeId;
                            values.attestationResponse = JSON.stringify(credentials);
                            values.authenticatorAttachment = credentials.authenticatorAttachment;

                            return resetFunction();
                        })
                        .catch((reason) => {
                            if (reason.name === 'InvalidStateError') {
                                message.error(intlMessage("authenticator.fido-token-already-registered"), 5);
                            } else {
                                message.error(intlMessage("authenticator.fido-token-not-supported"));
                            }
                        })
                        .finally(() => setResetActivationInProgress(false));
                })

                return;
            }

            if (authenticator?.type === AuthenticatorType.TLS_CERTIFICATE) {
                readFileAsText(values.certificate.file.originFileObj).then(dataUrl => {
                    values.certificate = dataUrl.substring(dataUrl.indexOf(',') + 1);

                    resetFunction();
                });

                return;
            }
        }

        resetFunction();
    }

    function onFinishEdit(values: any) {
        authenticatorService.update(authenticatorId!, values)
            .then(() => {
                message.success(intlMessage('authenticator-updated'));

                setEditMode(false);

                reload();
            });
    }

    function reload() {
        authenticatorService.get(authenticatorId!)
            .then(value => {
                setAuthenticator(value);

                form.setFieldsValue(value);

                auditLogsRef.current?.refresh();

                setLastReload(Date.now());
            })
            .catch(reason => {
                if (reason.response.status === 403 || reason.response.status === 404) {
                    navigate('/authenticators', {replace: true});
                } else {
                    return Promise.reject(reason);
                }
            })
    }

    function reloadAuditLogs(queryOptions: QueryOptions) {
        return auditLogService.getListForAuthenticator(authenticatorId, queryOptions)
    }

}

export default AuthenticatorDetail;

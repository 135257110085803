import AuthRequestType from "./AuthRequestType";

class AuthRequest {
    constructor(
        public id?: string,
        public type?: AuthRequestType,
        public username?: string,
        public application?: string,
        public geoIdent?: string,
        public userIp?: string,
        public actionName?: string,
        public createdAt?: Date,
        public expiresAt?: Date,
        public expiresInSeconds?: number) {
        //
    }

    public static fromPOJO(data: any) {
        const authRequest = new AuthRequest();

        authRequest.id = data.id;
        authRequest.type = data.type;
        authRequest.username = data.username;
        authRequest.application = data.application;
        authRequest.geoIdent = data.geoIdent;
        authRequest.userIp = data.userIp;
        authRequest.actionName = data.actionName;
        authRequest.createdAt = new Date(data.createdAt);
        authRequest.expiresAt = new Date(data.expiresAt);
        authRequest.expiresInSeconds = data.expiresInSeconds;

        return authRequest;
    }

    public get lifetimeInSeconds() {
        return (this.expiresAt!.getTime() - this.createdAt!.getTime()) / 1000;
    }

    public percentOfTimeRemaining(remainingSeconds: number) {
        return Math.round(remainingSeconds / (this.lifetimeInSeconds / 100))
    }

}

export default AuthRequest;
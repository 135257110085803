import Axios, {AxiosResponse} from "axios";
import WebPushSubscription from "../domain/WebPushSubscription";

class WebPushService {

    public addSubscription(name: string, endpoint: string, p256dh: string, auth: string): Promise<AxiosResponse> {
        return Axios.post("/web-push-subscriptions", {name, endpoint, p256dh, auth});
    }

    public deleteSubscription(endpoint: string): Promise<AxiosResponse> {
        return Axios.delete(`/web-push-subscriptions/${encodeURIComponent(endpoint)}`)
    }

    public getList(): Promise<WebPushSubscription[]> {
        return Axios.get("/web-push-subscriptions").then(value => {
            return value.data.map((objectJson: object) => WebPushSubscription.fromPOJO(objectJson));
        })
    }

}

export default WebPushService;

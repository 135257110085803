export class AuthenticatedAppConfig {

    constructor(
        public webPushPublicKey?: string) {
        //
    }

    public static fromPOJO(data: any) {
        const config = new AuthenticatedAppConfig();

        config.webPushPublicKey = data.webPushPublicKey;

        return config;
    }

}

import {useContext, useEffect, useRef} from "react";
import {AppContextContext} from "../../Contexts";
import {useIntlMessage} from "../../createIntlMessage";
import Axios from "axios";
import {message} from "antd";

function useActionConfirmation() {
    const appContext = useContext(AppContextContext);
    const intlMessage = useIntlMessage("");
    const retryActionRef = useRef<() => void>();

    useEffect(() => {
        window.addEventListener('message', onWindowMessage, false);

        return function cleanup() {
            window.removeEventListener('message', onWindowMessage, false);
        }
    }, []);

    function onWindowMessage(event: any) {
        // TODO: check origin

        console.debug("onWindowMessage: ", event);

        if (event.data.source !== '2Element' || event.data.type !== 'OIDC_CALLBACK') {
            return;
        }

        if (retryActionRef.current) {
            appContext.actionConfirmationCode = event.data.code;

            retryActionRef.current();
        }
    }

    function actionRequiredErrorHandler(retryAction: (...args: any[]) => any) {
        retryActionRef.current = retryAction;

        return function (error: any) {
            if (error.response?.status !== 412) {
                return error;
            }

            const actionConfirmationParams = error.response.data.actionConfirmationParams;

            Axios.post("/action-confirmation", {actionConfirmationParams})
                .then(acResponse => {
                    const location = acResponse.data.location;

                    const width = 450;
                    const height = 670;

                    const left = (window.screen.width / 2) - (width / 2);
                    const top = (window.screen.height / 2) - (height / 2) - 50;

                    const assertionWindow = window.open(location, "assertionWindow", `popup width=${width} height=${height} left=${left} top=${top}`);

                    if (!assertionWindow) {
                        message.error(intlMessage("error.cannot-open-browser-window"))

                        return;
                    }
                });
        }
    }

    return [actionRequiredErrorHandler];
}

export default useActionConfirmation;

import {LoadingOutlined} from "@ant-design/icons";
import {Breadcrumb, Button, Form, Input, message, Radio, Select, Spin} from "antd";
import {useForm, useWatch} from "antd/es/form/Form";
import React, {useContext, useEffect, useState} from "react";
import {DocumentTitle} from "../DocumentTitle";
import {useNavigate, useParams} from "react-router";
import {Link} from "react-router-dom";
import {AppContextContext, AuthenticatorServiceContext, AuthenticatorSetupStatusServiceContext, FidoServiceContext} from "../../Contexts";
import {useIntlMessage} from "../../createIntlMessage";
import {ServerConstraintViolationsHolder} from "../../sal-ui/ServerConstraintViolations";
import AuthenticatorSetupStatus from "../../service/AuthenticatorSetupStatus";
import {AuthenticatorType} from "../../domain/AuthenticatorType";
import {createExtended} from "@github/webauthn-json/extended";
import {readFileAsText} from "../../utils/PromiseUtils";
import appStyles from "../../App.module.css";
import {AuthenticatorProfile} from "../../domain/AuthenticatorProfile";
import {renderActivationFidoElements, renderActivationTlsCertificateElements, renderActivationYubiKeyElements, renderAttributeMobileElements, renderAuthenticatorProfile, renderEnrollment} from "./AuthenticatorFormHelper";
import InvitationVerificationCodeEnrollment from "../../domain/InvitationVerificationCodeEnrollment";
import EnrollmentType from "../../domain/EnrollmentType";

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function AuthenticatorAdd() {
    const appContext = useContext(AppContextContext);
    const setupStatusService = useContext(AuthenticatorSetupStatusServiceContext);
    const authenticatorService = useContext(AuthenticatorServiceContext);
    const fidoService = useContext(FidoServiceContext);
    const navigate = useNavigate();
    const {userId, adminId}: any = useParams();
    const intlMessage = useIntlMessage("authenticator-add");
    const [form] = useForm();
    const type = useWatch<AuthenticatorType>("type", form);
    const discoverableCredential = useWatch<boolean>("discoverableCredential", form);
    const [setupStatus, setSetupStatus] = useState<AuthenticatorSetupStatus>();
    const [inProgress, setInProgress] = useState(false);
    const profile = useWatch<AuthenticatorProfile>("profile", form);
    const enrollment = useWatch<EnrollmentType>(["basicAttributes", "enrollment", "type"], form);
    const verificationCodeEnrollment = useWatch<InvitationVerificationCodeEnrollment>(["basicAttributes", "verificationCodeEnrollment", "type"], form);

    useEffect(() => {
        if (type === AuthenticatorType.MOBILE) {
            form.setFieldValue(["basicAttributes", "enrollment", "type"], EnrollmentType.INVITATION_NO_NOTIFICATION)
        } else {
            form.setFieldValue(["basicAttributes", "enrollment", "type"], EnrollmentType.DIRECT)
        }
    }, [type]);

    useEffect(() => form.setFieldValue(["basicAttributes", "verificationCodeEnrollment", "type"], InvitationVerificationCodeEnrollment.NONE), [type, enrollment]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setupStatusService.getSetupStatus().then(value => setSetupStatus(value));

        authenticatorService.randomName()
            .then(name => {
                form.setFieldsValue({
                    name
                });
            });
    }, [])

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    const tailLayout = (appContext.isTabletOrMobile)
        ? {wrapperCol: {span: 24}}
        : {wrapperCol: {offset: 8, span: 16}};

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage('title')}`}>
            <Spin spinning={!setupStatus} indicator={<LoadingOutlined style={{fontSize: 24}} spin={true}/>}>
                <Breadcrumb className={"common__breadcrumb"}>
                    <Breadcrumb.Item><Link to={"/"}>{appContext.config?.appName}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={"/authenticators"}>{intlMessage('common.authenticators')}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{intlMessage('title')}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{intlMessage('title')}</h1>

                <Form
                    {...layout}
                    form={form}
                    className={appStyles.formHorizontal}
                    onFinish={onFinish}
                    initialValues={{
                        type: 'FIDO',
                        basicAttributes: {
                            enrollment: {type: 'DIRECT'},
                            verificationCodeEnrollment: {type: 'DIRECT'},
                        }
                    }}>
                    <div className={appStyles.formSectionTitle}>{intlMessage("common.section-basic")}</div>

                    <Form.Item
                        name={["basicAttributes", "name"]}
                        label={intlMessage("common.name")}
                        rules={[{required: true, message: intlMessage("common.name-required")}]}>
                        <Input maxLength={100}/>
                    </Form.Item>

                    <Form.Item name={"type"} label={intlMessage("type")}>
                        <Select>
                            <Select.Option value={"FIDO"}>U2F / FIDO2</Select.Option>
                            <Select.Option value={"TLS_CERTIFICATE"}>{intlMessage("authenticator.tls-certificate")}</Select.Option>
                            <Select.Option value={"MOBILE"}>2Element Mobile</Select.Option>
                            {
                                setupStatus?.yubiCloudServiceStatus === 'ENABLED' && <Radio value={"YUBIKEY"}>YubiKey OTP</Radio>
                            }
                        </Select>
                    </Form.Item>

                    {renderAttributeFormElements()}

                    <div className={appStyles.formSectionTitle}>{intlMessage("authenticator.section-enrollment")}</div>

                    {renderEnrollment(form, serverViolationsHolder, intlMessage, type, enrollment, setupStatus!, verificationCodeEnrollment)}

                    {enrollment === EnrollmentType.DIRECT &&
                        <>
                            <div className={appStyles.formSectionTitle}>{intlMessage("authenticator.section-type-specific")}</div>

                            {renderAuthenticatorProfile(form, serverViolationsHolder, intlMessage, type)}

                            {renderActivationFormElements()}
                        </>
                    }

                    <Form.Item {...tailLayout} className={appStyles.formButtons}>
                        <Button type={"primary"} htmlType={"submit"}>{intlMessage('add-authenticator')}</Button>
                        <Button onClick={onCancel}>{intlMessage("common.cancel")}</Button>
                    </Form.Item>
                </Form>
            </Spin>
        </DocumentTitle>
    )

    function renderAttributeFormElements() {
        if (type === AuthenticatorType.MOBILE) {
            return renderAttributeMobileElements(form, serverViolationsHolder, intlMessage);
        }
    }

    function renderActivationFormElements() {
        if (type === AuthenticatorType.YUBIKEY) {
            return renderActivationYubiKeyElements(form, serverViolationsHolder, intlMessage);
        } else if (type === AuthenticatorType.FIDO) {
            return renderActivationFidoElements(form, serverViolationsHolder, intlMessage, type, enrollment, profile, discoverableCredential);
        } else if (type === AuthenticatorType.TLS_CERTIFICATE) {
            return renderActivationTlsCertificateElements(form, serverViolationsHolder, intlMessage);
        }
    }

    function onCancel() {
        navigate(`/authenticators`);
    }

    function onFinish(values: any) {
        const authenticatorValues = Object.assign({userId, adminId}, values);

        const addFunction = () => {
            authenticatorService.add(authenticatorValues)
                .then((deviceId) => {
                    navigate(`/authenticators/${deviceId}`, {replace: true});
                })
                .catch(reason => {
                    if (reason && reason.response && reason.response.data && ServerConstraintViolationsHolder.containsConstraintViolations(reason.response.data)) {
                        serverViolationsHolder.violations = reason.response.data;

                        form.validateFields();

                        if (reason.response.data.constraintViolations['registration']) {
                            message.error(intlMessage("authenticator.fido-token-not-supported"));
                        } else if (reason.response.data.constraintViolations['fingerprint']) {
                            message.error(intlMessage("authenticator.tls-certificate-already-exists"));
                        }
                    }

                    return Promise.reject(reason);
                })
                .finally(() => setInProgress(false));
        };

        setInProgress(true);

        if (enrollment === EnrollmentType.DIRECT) {
            if (type === AuthenticatorType.FIDO) {
                fidoService.startRegistration(values.basicAttributes.name, values.discoverableCredential, values.userVerificationRequired).then(response => {
                    return createExtended(response.publicKeyCredentialCreationOptions)
                        .then(credentials => {
                            authenticatorValues.fidoChallengeId = response.fidoChallengeId;
                            authenticatorValues.attestationResponse = JSON.stringify(credentials);
                            authenticatorValues.authenticatorAttachment = credentials.authenticatorAttachment;

                            addFunction();
                        })
                        .catch((reason) => {
                            if (reason.name === 'InvalidStateError') {
                                message.error(intlMessage("authenticator.fido-token-already-registered"), 5);
                            } else {
                                message.error(intlMessage("authenticator.fido-token-not-supported"));
                            }
                        })
                        .finally(() => setInProgress(false));
                })

                return;
            }

            if (type === AuthenticatorType.TLS_CERTIFICATE) {
                readFileAsText(values.certificate.file.originFileObj).then(dataUrl => {
                    authenticatorValues.certificate = dataUrl.substring(dataUrl.indexOf(',') + 1);

                    addFunction();
                });

                return;
            }
        }

        addFunction();
    }
}


export default AuthenticatorAdd;

import {IntlShape, useIntl} from "react-intl";
import React from "react";

export type IntlMessageFormatter = (id: string, values?: (Record<string, any> | undefined)) => string;

export function formatIntlMessage(intl: IntlShape, prefix: string, id: string, values?: Record<string, any>) {
    const finalId = (id.includes('.')) ? id : `${prefix}.${id}`;

    const output = intl.formatMessage({id: finalId}, values);

    if ((Array.isArray(output) && output.length > 0) || (typeof output === "string" && output.trim().length > 0)) {
        return output;
    } else {
        if ((typeof output === "string" && output.trim().length === 0)) {
            return finalId + "__existuje";
        } else {
            return finalId;
        }
    }
}

export function useIntlMessage(prefix: string): IntlMessageFormatter {
    const intl = useIntl();

    return function (id: string, values?: Record<string, any>) {
        if (values === undefined) {
            values = {};
        }

        values.b = (values.disableMarkup) ? (chunks: any) => chunks : (chunks: any) => React.createElement('b', {}, chunks);
        values.br = (values.disableMarkup) ? (chunks: any) => chunks : () => React.createElement('br');
        values.i = (values.disableMarkup) ? (chunks: any) => chunks : (chunks: any) => React.createElement('i', {}, chunks);

        return formatIntlMessage(intl, prefix, id, values);
    }

}

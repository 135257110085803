import Axios from "axios";
import AuthenticatorAssignment from "../domain/AuthenticatorAssignment";
import PagedResult from "./PagedResult";

class AuthenticatorAssignmentService {

    public getAssignments(): Promise<PagedResult<AuthenticatorAssignment>> {
        return Axios.get(`/authenticator-assignments`)
            .then(value => {
                const authenticators: AuthenticatorAssignment[] = [];

                value.data.data.forEach((authenticatorJson: object) => {
                    authenticators.push(AuthenticatorAssignment.fromPOJO(authenticatorJson));
                });

                return new PagedResult(value.data.total, authenticators);
            })
    }

    public delete(authenticatorAssignmentId: string): Promise<void> {
        return Axios.delete(`/authenticator-assignments/${authenticatorAssignmentId}`);
    }

}

export default AuthenticatorAssignmentService;

import QueryOptions from "../sal-ui/QueryOptions";
import PagedResult from "./PagedResult";
import Axios, {AxiosResponse} from "axios";
import Session from "../domain/Session";

class SessionService {

    public getList(queryOptions: QueryOptions): Promise<PagedResult<Session>> {
        return Axios.get(`/sessions?${queryOptions.toQueryParams()}`)
            .then(value => {
                const objectList: Session[] = [];

                value.data.data.forEach((objectJson: object) => {
                    objectList.push(Session.fromPOJO(objectJson));
                });

                return new PagedResult(value.data.total, objectList);
            })
    }

    public delete(id: string): Promise<AxiosResponse> {
        return Axios.delete(`/sessions/${id}`);
    }

}

export default SessionService;
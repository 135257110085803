import Axios from "axios";
import AuthenticatorSetupStatus from "./AuthenticatorSetupStatus";

class AuthenticatorSetupStatusService {

    public getSetupStatus(): Promise<AuthenticatorSetupStatus> {
        return Axios.get(`/authenticator-setup-status`).then(value => AuthenticatorSetupStatus.fromPOJO(value.data))
    }

}

export default AuthenticatorSetupStatusService;

import {Button, Divider, Layout, Menu} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContextContext, AuthServiceContext, ClientNotificationServiceContext} from "../../Contexts";
import "./HomePage.css";
import {routes, routesMap} from "../Routes";
import SelectLang from "../SelectLang";
import {Link, matchRoutes, useLocation, useRoutes} from "react-router-dom";
import {useIntlMessage} from "../../createIntlMessage";
import Icon, {AuditOutlined, LoadingOutlined, MenuOutlined, PieChartFilled, UserOutlined} from "@ant-design/icons";
import {IconAssignment, IconExit} from "../common/CustomIcons";
import {GrKey} from "react-icons/gr";
import {ItemType} from "antd/es/menu/hooks/useItems";
import {IoNotificationsOutline, IoTicketOutline} from "react-icons/io5";
import styles from "./HomePage.module.css";
import ProfileMenu from "./ProfileMenu";

const {Header, Content, Sider} = Layout;

const JWT_CHECK_INTERVAL_IN_MS = 1000;

function HomePage() {
    const appContext = useContext(AppContextContext);
    const authService = useContext(AuthServiceContext);
    const clientNotificationService = useContext(ClientNotificationServiceContext);
    const intlMessage = useIntlMessage("home-page");
    const location: any = useLocation();
    const jwtChecked = useRef(false);
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [siderCollapsed, setSiderCollapsed] = useState(false);

    const matchedRoute = firstMatchedRoute();

    const selectedKeys = (matchedRoute) ? [matchedRoute.menuKey!] : [];

    const route = useRoutes(routes);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setOpenKeys((matchedRoute) ? (matchedRoute.menuOpenKey) ? [matchedRoute.menuOpenKey!] : [] : []);

        checkJwtTokenExpireAt();

        const timerHandle = setInterval(checkJwtTokenExpireAt, JWT_CHECK_INTERVAL_IN_MS);

        clientNotificationService.connectToServer();

        return function cleaup() {
            clearInterval(timerHandle);

            clientNotificationService.disconnectFromServer();
        }
    }, []);

    useEffect(() => setSiderCollapsed(appContext.isTabletOrMobile!), [appContext.isTabletOrMobile]);

    if (!jwtChecked.current) {
        return (
            <LoadingOutlined className={"home-page__loading"}/>
        )
    }

    let mainMenuItems: ItemType[] = [
        {
            key: routesMap.Dashboard.menuKey,
            icon: <PieChartFilled/>,
            label: <Link to={routesMap.Dashboard.path}>{intlMessage("menu-dashboard")}</Link>
        },
        {
            key: routesMap.AuthenticatorList.menuKey,
            icon: <GrKey className={"anticon reacticon"}/>,
            label: <Link to={routesMap.AuthenticatorList.path}>{intlMessage("common.authenticators")}</Link>
        },
        {
            key: routesMap.AuthorizationRequestList.menuKey,
            icon: <AuditOutlined/>,
            label: <Link to={routesMap.AuthorizationRequestList.path}>{intlMessage("common.authorization-requests")}</Link>
        },
        {
            key: routesMap.WebPushSubscriptionList.menuKey,
            icon: <IoNotificationsOutline className={"anticon reacticon"}/>,
            label: <Link to={routesMap.WebPushSubscriptionList.path}>{intlMessage("common.web-push-subscriptions")}</Link>
        },
        {
            key: routesMap.ActiveSessionList.menuKey,
            icon: <IoTicketOutline className={"anticon reacticon"}/>,
            label: <Link to={routesMap.ActiveSessionList.path}>{intlMessage("common.active-sessions")}</Link>
        },
        {
            key: routesMap.AuditLogList.menuKey,
            icon: <Icon component={IconAssignment}/>,
            label: <Link to={routesMap.AuditLogList.path}>{intlMessage("common.audit-logs")}</Link>
        }
    ];

    if (appContext.isTabletOrMobile) {
        mainMenuItems.push(
            {
                key: "logout-divider",
                type: "divider",
            },
            {
                key: "username",
                icon: <UserOutlined/>,
                label: <span>{appContext.user?.username}</span>,
            },
            {
                key: "logout",
                icon: <Icon component={IconExit}/>,
                label: <span>{intlMessage("menu-logout")}</span>,
                onClick: logout
            }
        );
    }

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Header className={styles.header}>
                {
                    appContext.isTabletOrMobile &&

                    <Button className={"home-page__menu-button"}
                            onClick={() => setSiderCollapsed(prevState => !prevState)}
                            icon={<MenuOutlined/>}/>
                }

                <div className={styles.logo}>
                    <Link to={"/"}>
                        {appContext.config?.logo !== "" ?
                            <img src={appContext.config?.logo} alt={appContext.config?.fqdn}/> :
                            <img src="/logo.svg" alt={appContext.config?.fqdn}/>
                        }
                    </Link>
                </div>

                <div className={styles.center}/>

                {
                    !appContext.isTabletOrMobile &&

                    <div className={styles.toolbar}>
                        <SelectLang/>

                        <Divider type="vertical"/>

                        <ProfileMenu/>
                    </div>
                }
            </Header>
            <Layout>
                <Sider width={240} collapsedWidth={0} collapsible={true} trigger={null} collapsed={siderCollapsed}>
                    <Menu className={"home-page__main-menu"}
                          theme="dark"
                          openKeys={openKeys}
                          onOpenChange={keys => setOpenKeys(keys as string[])}
                          onSelect={() => appContext.isTabletOrMobile && setSiderCollapsed(prevState => !prevState)}
                          selectedKeys={selectedKeys} mode="inline"
                          onClick={onMenuClick}
                          items={mainMenuItems}
                    />

                    {
                        !siderCollapsed &&

                        <div id={"sonpo-logo"}>
                            <a href={"https://www.sonpo.cz"} target={"_blank"}>
                                <img src="/sonpo.svg" style={{width: "80px"}}/><br/>
                                SONPO, a.s. © 2023
                                <div className={"home-page__version"}>
                                    build {appContext.config?.version}
                                </div>
                            </a>
                        </div>
                    }
                </Sider>

                <Content className={"home-page__content"}>
                    <div style={{padding: 24, minHeight: 300}}>
                        {route}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );

    function onMenuClick(clickParam: any) {
        window.scrollTo(0, 0);

        if (clickParam.key === "logout") {
            logout();
        }
    }

    function firstMatchedRoute(): any | null {
        const matchedRoutes = matchRoutes(routes, location.pathname);

        if (matchedRoutes === null) {
            return null;
        }

        if (matchedRoutes.length === 1) {
            return matchedRoutes[0].route;
        } else {
            return null;
        }
    }

    function logout() {
        authService.logout();
    }

    function checkJwtTokenExpireAt() {
        if (appContext.sessionExpiresAt) {
            if (appContext.sessionExpiresAt.getTime() < (new Date().getTime() + JWT_CHECK_INTERVAL_IN_MS)) {
                logout();
            } else {
                jwtChecked.current = true;
            }
        }
    }

}

export default HomePage;

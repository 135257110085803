import dayjs from "dayjs";

export function formatDateTime(date?: Date) {
    if (date === undefined) {
        return '?';
    }

    return dayjs(date).format("YYYY-MM-DD HH:mm:ss")
}

export function capitalize(text: string) {
    return text[0].toUpperCase() + text.slice(1);
}

export function uncapitalize(text: string) {
    return text[0].toLowerCase() + text.slice(1);
}

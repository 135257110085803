import {Breadcrumb} from "antd";
import React, {useContext, useEffect, useRef} from "react";
import {DocumentTitle} from "../DocumentTitle";
import {AppContextContext, AuditLogServiceContext} from "../../Contexts";
import {useIntlMessage} from "../../createIntlMessage";
import {ObjectAuditLogList, ObjectAuditLogListDelegate} from "./ObjectAuditLogList";
import QueryOptions from "../../sal-ui/QueryOptions";
import {Link} from "react-router-dom";

function AuditLogList() {
    const appContext = useContext(AppContextContext);
    const auditLogService = useContext(AuditLogServiceContext);
    const intlMessage = useIntlMessage("audit-log-list");
    const auditLogsRef = useRef<ObjectAuditLogListDelegate>(null);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        reload();
    }, []);

    return (
        <DocumentTitle title={`${appContext.config?.appName}: ${intlMessage("title")}`}>
            <>
                <Breadcrumb className={"common__breadcrumb"}>
                    <Breadcrumb.Item><Link to={"/"}>{appContext.config?.appName}</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{intlMessage("title")}</Breadcrumb.Item>
                </Breadcrumb>

                <h1>{intlMessage("title")}</h1>

                <ObjectAuditLogList loadData={reloadAuditLogs} ref={auditLogsRef}/>
            </>
        </DocumentTitle>
    )

    function reload() {
        auditLogsRef.current?.refresh();
    }

    function reloadAuditLogs(queryOptions: QueryOptions) {
        return auditLogService.getListForUser(queryOptions);
    }

}

export default AuditLogList;

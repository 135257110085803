import {SessionType} from "./SessionType";
import {MultiFactorMethod} from "./MultiFactorMethod";

class Session {
    constructor(
        public id?: string,
        public type?: SessionType,
        public createdAt?: Date,
        public lastActivity?: Date,
        public expiresAt?: Date,
        public ipAddress?: string,
        public geoIdent?: string,
        public userAgent?: string,
        public method?: MultiFactorMethod) {
        //
    }

    public static fromPOJO(data: any) {
        const session = new Session();

        session.id = data.id;
        session.type = data.type;
        session.createdAt = new Date(data.createdAt);
        session.lastActivity = new Date(data.lastActivity);
        session.expiresAt = (data.expiresAt) ? new Date(data.expiresAt) : undefined;
        session.ipAddress = data.ipAddress;
        session.geoIdent = data.geoIdent;
        session.userAgent = data.userAgent;
        session.method = data.method;

        return session;
    }
}

export default Session;

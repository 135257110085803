import {SortOrder} from "antd/es/table/interface";
import {TablePaginationConfig} from "antd/lib/table";
import {useEffect, useRef, useState} from "react";
import QueryOptions from "./QueryOptions";

interface PersistentData {
    currentPage: number;
    pageSize: number;
    orderBy: string;
}

export interface TableHandler {
    queryOptions: QueryOptions;

    onTableChange: (pagination: any, filters: any, sorter: any) => void;

    onSearchSubmit: (filter: string, values?: any) => void;

    columnSortOrder: (column: string) => SortOrder | undefined;

    pagination: TablePaginationConfig;

    updateTotal: (total: number) => void;

    setLoading: (loading: boolean) => void;

    loading: boolean;

    reload: () => void;
}

export interface TableHandlerOptions {
    reloadFunction: () => Promise<void>;
    initialPageSize?: number;
    fixedFilter?: string;
    persistentIdent?: string;
    autoload?: boolean;
}

export function useTableHandler(defaultSortOrder: string, options: TableHandlerOptions): TableHandler {
    const initialPageSize = (options.initialPageSize) ? options.initialPageSize : 25;

    const queryOptions = useRef(new QueryOptions(defaultSortOrder, initialPageSize, 0, options.fixedFilter));

    const paginationConfig = useRef<TablePaginationConfig>({
        pageSize: initialPageSize,
        pageSizeOptions: ['10', '25', '50', '100', '200', '500'],
        position: ['bottomRight', 'topRight'],
        showSizeChanger: true,
        current: 1,
    });

    const initialized = useRef(false);

    const [loading, setLoading] = useState<boolean>(false);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        loadState();

        if (options.autoload !== false) {
            reload();
        }
    }, []);

    function onTableChange(pagination: any, filters: any, sorter: any) {
        if (sorter.field) {
            let key = (Array.isArray(sorter.field)) ? sorter.field.join(".") : sorter.field;

            if (sorter.columnKey) {
                key = sorter.columnKey;
            }

            queryOptions.current.orderBy = `${key} ${(sorter.order === 'descend') ? 'desc' : 'asc'}`;
        }

        queryOptions.current.top = pagination.pageSize;
        queryOptions.current.skip = ((pagination.current - 1) * pagination.pageSize);

        const pageSizeChanged = (paginationConfig.current.pageSize !== pagination.pageSize);

        if (pageSizeChanged) {
            pagination.current = 1;

            queryOptions.current.skip = 0;
        }

        saveState(pagination);

        paginationConfig.current = pagination;

        reload();
    }

    function onSearchSubmit(filter: string) {
        const filterChanged = queryOptions.current.filter !== filter;

        if (filterChanged) {
            queryOptions.current.filter = (options.fixedFilter && filter && filter !== '') ? `${options.fixedFilter} and ${filter}` : filter;

            queryOptions.current.skip = 0;

            paginationConfig.current = Object.assign({}, paginationConfig.current, {current: 1});

            saveState(paginationConfig.current);
        }

        // zamezíme úvodnímu nahrávání z filtru pokud ještě nejsme připravení
        if (initialized.current) {
            reload();
        }
    }

    function reload() {
        setLoading(true);

        options.reloadFunction().finally(() => setLoading(false));
    }

    function saveState(pagination: TablePaginationConfig) {
        if (!options.persistentIdent) {
            return;
        }

        const data: PersistentData = {
            pageSize: pagination.pageSize || initialPageSize,
            currentPage: pagination.current || 1,
            orderBy: queryOptions.current.orderBy || ""
        }

        localStorage.setItem(getQualifiedIdent(), JSON.stringify(data));
    }

    function loadState() {
        initialized.current = true;

        if (!options.persistentIdent) {
            return;
        }

        const serializedData = localStorage.getItem(getQualifiedIdent());

        if (!serializedData) {
            return;
        }

        const data: PersistentData = JSON.parse(serializedData);

        paginationConfig.current = Object.assign({}, paginationConfig.current, {pageSize: data.pageSize, current: data.currentPage});

        queryOptions.current.orderBy = data.orderBy || "";
        queryOptions.current.top = data.pageSize;
        queryOptions.current.skip = ((data.currentPage - 1) * data.pageSize);
    }

    function getQualifiedIdent() {
        return `2Element.User.Table.${options.persistentIdent}`;
    }

    function columnSortOrder(column: string): SortOrder | undefined {
        const [attribute, sortOrder] = queryOptions.current.orderBy.split(" ");

        if (column === attribute) {
            return sortOrder === "asc" ? 'ascend' : 'descend';
        }

        return undefined;
    }

    return {
        queryOptions: queryOptions.current,
        onTableChange: onTableChange,
        onSearchSubmit: onSearchSubmit,
        columnSortOrder: columnSortOrder,
        pagination: paginationConfig.current,
        updateTotal: (total: number) => paginationConfig.current = Object.assign({}, paginationConfig.current, {total}),
        setLoading: (state: boolean) => setLoading(state),
        loading: loading,
        reload
    }
}

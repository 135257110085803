import React, {ForwardedRef, forwardRef, useImperativeHandle, useState} from "react";
import {Button, Card, Col, List, Row, Space, Spin, Switch, Table} from "antd";
import {MinusOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import {useIntlMessage} from "../../createIntlMessage";
import styles from "./ObjectAuditLogList.module.css";
import {TableHandler, useTableHandler} from "../../sal-ui/TableHandler";
import AuditLog from "../../domain/AuditLog";
import PagedResult from "../../service/PagedResult";
import QueryOptions from "../../sal-ui/QueryOptions";
import {appContext} from "../../Contexts";
import appStyles from "../../App.module.css";
import Flag from "../flag/Flag";

interface Props {
    loadData: (queryOptions: QueryOptions) => Promise<PagedResult<any>>;
    hidePrincipal?: boolean;
    initialPageSize?: number;
    simpleMode?: boolean;
}

export interface ObjectAuditLogListDelegate {
    tableHandler: TableHandler;

    refresh(): void;
}

function AuditLogCard({item, showLogType}: { item: AuditLog, showLogType: boolean }) {
    const [expanded, setExpanded] = useState(false);

    return (
        <Card title={
            <>
                {dayjs(item.timestamp).format("YYYY-MM-DD HH:mm:ss")}
            </>}
              extra={<Button icon={(expanded) ? <MinusOutlined/> : <PlusOutlined/>} size={"small"} onClick={() => setExpanded(prevState => !prevState)}/>}
              size={"small"}>
            {showLogType && <div className={styles.type}>{item.type}</div>}
            {item.message} <br/>
            {expanded && renderAttributes(item)}
        </Card>
    )

    function renderAttributes(record: AuditLog) {
        return (
            <List dataSource={(record.attributes) ? Object.entries(record.attributes).sort((a, b) => b[0].localeCompare(a[0])) : []}
                  className={styles.list}
                  renderItem={(item: any) => (
                      <List.Item className={styles.listItem}>
                          <div><b>{item[0]}</b></div>
                          <div>{item[1]}</div>
                      </List.Item>
                  )}
            />
        )
    }
}

export const ObjectAuditLogList = forwardRef(function ({loadData, hidePrincipal, initialPageSize, simpleMode}: Props, ref: ForwardedRef<ObjectAuditLogListDelegate>) {
    const intlMessage = useIntlMessage("object-detail-audit-log-list");
    const tableHandler = useTableHandler("timestamp desc", {reloadFunction: reload, initialPageSize});
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const [showLogType, setShowLogType] = useState<boolean>(false);
    const [objects, setObjects] = useState<PagedResult<any>>();

    useImperativeHandle(ref, () => ({
        refresh: () => tableHandler.reload(),
        tableHandler
    }));

    return (
        <>
            {
                !simpleMode &&

                <div className={`${styles.topButtonBar} ${appStyles.topButtonBar}`}>
                    <Button icon={<ReloadOutlined/>} onClick={tableHandler.reload} className={appStyles.btnSeamless}/>
                    {
                        !appContext.isTabletOrMobile &&

                        <>
                            <Button icon={<PlusOutlined/>} onClick={expandAll} title={intlMessage("common.expand-all")} className={appStyles.btnSeamless}/>
                            <Button icon={<MinusOutlined/>} onClick={collapseAll} title={intlMessage("common.collapse-all")} className={appStyles.btnSeamless}/>
                        </>
                    }
                    <span style={{paddingLeft: "16px", float: "right"}}>{intlMessage("audit-log.show-column-type")} <Switch onChange={setShowLogType}/></span>
                </div>
            }

            {appContext.isTabletOrMobile && renderList()}

            {!appContext.isTabletOrMobile && renderTable()}
        </>
    )

    function renderList() {
        return (
            <Spin spinning={tableHandler.loading}>
                <List dataSource={objects?.data}
                      renderItem={(item: AuditLog) => (
                          <List.Item className={styles.listItem}>
                              <AuditLogCard item={item} showLogType={showLogType}/>
                          </List.Item>
                      )}
                />
            </Spin>
        )
    }

    function renderTable() {
        return (
            <Table className={styles.table}
                   loading={tableHandler.loading}
                   showSorterTooltip={false}
                   dataSource={objects?.data}
                   size="small"
                   onChange={tableHandler.onTableChange}
                   pagination={(!simpleMode) ? tableHandler.pagination : false}
                   expandable={
                       (!simpleMode)
                           ? {
                               expandedRowRender: renderAuditLogRecord,
                               rowExpandable: record => (record.attributes !== undefined),
                               expandedRowKeys,
                               indentSize: 0,
                               onExpand: (expanded: boolean, record: AuditLog) => {
                                   if (expanded) {
                                       setExpandedRowKeys(prevState => prevState.concat(record.id!));
                                   } else {
                                       setExpandedRowKeys(prevState => prevState.filter(value => value !== record.id));
                                   }
                               }
                           }
                           : undefined
                   }
                   rowKey="id">
                <Column dataIndex="timestamp" title={intlMessage("audit-log-list.timestamp")} width={"180px"} render={value => dayjs(value).format("YYYY-MM-DD HH:mm:ss")}/>
                <Column dataIndex="ipAddress" title={intlMessage("common.ip-address")} render={renderIpAddress}/>
                {
                    !hidePrincipal && <Column dataIndex="principalText" title={intlMessage("common.user")} className={styles.columnPrincipal}/>
                }

                {
                    showLogType && <Column dataIndex="type" title={intlMessage("audit-log.type")}/>
                }
                <Column dataIndex="message" title={intlMessage("audit-log-list.message")}/>
            </Table>
        )
    }

    function renderIpAddress(value: any, record: AuditLog) {
        const ipAddress = record?.attributes?.['ip_address'] || record?.attributes?.['user_ip'];

        if (ipAddress === undefined || ipAddress === '') {
            return;
        }

        return <Flag cca2={record.attributes['geo_ident']}>{ipAddress}</Flag>;
    }

    function renderAuditLogRecord(record: AuditLog) {
        return (
            <Row>
                <Col span={12}>
                    <Table dataSource={(record.attributes) ? Object.entries(record.attributes).sort((a, b) => b[0].localeCompare(a[0])) : []}
                           showSorterTooltip={false}
                           size="small"
                           rowKey="0"
                           bordered={true}
                           tableLayout={"fixed"}
                           title={() => <h4>{intlMessage("audit-log-list.attributes-table")}</h4>}
                           className={styles['inner-table']}
                           pagination={false}>
                        <Column dataIndex="0" title={intlMessage("audit-log-list.attribute-name")} className={styles.attributeKey}/>
                        <Column dataIndex="1" title={intlMessage("audit-log-list.attribute-value")} render={renderAttributeValue} className={styles.attributeValue}/>
                    </Table>
                </Col>
            </Row>
        )
    }

    function renderAttributeValue(value: any, _record: any) {
        if (typeof value === 'object') {
            return <div className={"audit-log-detail__attribute-value"}>{JSON.stringify(value)}</div>;
        }

        return (
            <Space size={"small"}>
                <div className={"audit-log-detail__attribute-value"}>
                    {value}
                </div>
            </Space>
        )
    }

    function expandAll() {
        let expandedIds: string[] = [];

        objects?.data.forEach(record => {
            expandedIds.push(record.id!);
        })

        setExpandedRowKeys(expandedIds);
    }

    function collapseAll() {
        setExpandedRowKeys([]);
    }

    function reload() {
        return loadData(tableHandler.queryOptions).then(value => {
            setObjects(value);

            tableHandler.updateTotal(value.total);
        })
    }

});
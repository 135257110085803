import _ from 'lodash';
import React, {useContext, useEffect, useRef} from "react";
import Chart from 'chart.js/auto';
import 'chartjs-adapter-dayjs-3';
import DateTimePeriod from "../../domain/DateTimePeriod";
import dayjs from "dayjs";
import {StatisticsServiceContext} from "../../Contexts";
import {ActiveElement, ChartEvent} from "chart.js";
import {useNavigate} from "react-router";
import {chartColors, defaultChartOptions} from "./ChartUtils";
import {formatMultiFactorMethod} from "../../domain/MultiFactorMethod";
import {useIntlMessage} from "../../createIntlMessage";

interface IProps {
    period: DateTimePeriod;
}

function MultiFactorMethodAuthRequestStatistics({period}: IProps) {
    const statisticsService = useContext(StatisticsServiceContext);
    const navigate = useNavigate();
    const chartElRef = useRef<HTMLCanvasElement>(null);
    const chartRef = useRef<Chart>();
    const intlMessage = useIntlMessage("");

    useEffect(() => {
        statisticsService.getStatistics(period, "methods").then(data => {
            const methods = _.uniq(data.map(value => value.method)).sort();

            const groupedData = _.groupBy(data, value => value.method);

            chartRef.current!.data.datasets = methods.map((value: any, index: number) => {
                return {
                    label: formatMultiFactorMethod(intlMessage, value),
                    stack: "",
                    data: groupedData[value].map(item => {
                        return {
                            x: new Date(item.dateTime).getTime(), y: item.count, method: item.method
                        }
                    }),
                    backgroundColor: chartColors[index]
                }
            });

            chartRef.current!.update();
        })

        chartRef.current = new Chart((chartElRef.current as HTMLCanvasElement),
            Object.assign(defaultChartOptions(period, onClick, []), {onClick})
        );

        return () => {
            chartRef.current?.destroy();
        }
    }, [period]);

    return (
        <canvas width="400" height="250" ref={chartElRef}/>
    )

    function onClick(_event: ChartEvent, elements: ActiveElement[], chart: Chart) {
        const element = elements[0];

        const item: any = chart.data.datasets[element.datasetIndex].data[element.index];

        const timestampFrom = dayjs(item.x).toISOString();
        const timestampTo = (period === DateTimePeriod.Day)
            ? dayjs(item.x).add(1, 'hour').toISOString()
            : dayjs(item.x).add(1, 'day').toISOString();

        const filter: any = {
            typeHints: {"timestampFrom": "dayjs", "timestampTo": "dayjs"},
            values: {
                types: ["AUTH_REQUEST_ALLOWED"],
                severity: "INFO",
                timestampFrom,
                timestampTo,
                attributes: [
                    {name: "multi_factor_method", value: item.method}
                ]
            }
        }

        const searchParams = new URLSearchParams();

        searchParams.set("filter", JSON.stringify(filter))

        navigate(`/audit-logs?${searchParams}`);
    }

}

export default MultiFactorMethodAuthRequestStatistics;

import _ from "lodash";

type ClientNotificationHandler = (clientNotification: ClientNotification) => void;

interface ClientNotification {
    event: string;
    target?: string;
    data?: any;
}

interface ClientNotificationSubscription {
    event: string;
    target?: string;
    handler: ClientNotificationHandler;
}

export class ClientNotificationService {

    private webSocket?: WebSocket;

    private subscriptions: ClientNotificationSubscription[] = [];

    public connectToServer() {
        this.webSocket = new WebSocket(((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host + "/api/user/notifications");

        this.webSocket.onerror = ev => {
            console.debug("onerror: ", ev);
        }

        this.webSocket.onopen = ev => {
            console.debug("onopen: ", ev);

            const existingSubscriptions = this.subscriptions;

            this.subscriptions = [];

            for (const subscription of existingSubscriptions) {
                this.subscribe(subscription.event, subscription.handler, subscription.target);
            }
        }

        this.webSocket.onclose = ev => {
            console.debug("onclose: socket closed, reopening connection");

            this.connectToServer();
        }

        this.webSocket.onmessage = message => {
            console.debug("onmessage: ", message);

            const notification = JSON.parse(message.data);

            const event = notification.event;
            const target = notification.target;
            const data = notification.data;

            for (const subscription of this.subscriptions) {
                if (subscription.event === event && subscription.target === target) {
                    subscription.handler && subscription.handler({event, target, data});
                }
            }
        }


    }

    public disconnectFromServer() {
        this.webSocket?.close();
    }

    public subscribe(event: string, handler: ClientNotificationHandler, target?: string) {
        if (this.webSocket?.readyState === WebSocket.CONNECTING) {
            console.debug("WebSocket is in CONNECTING state, postponing subscription");

            setTimeout(() => this.subscribe(event, handler, target), 1000);

            return;
        }

        if (this.webSocket?.readyState !== WebSocket.OPEN) {
            console.debug("WebSocket is not in OPEN state, ignoring subscription");

            return;
        }

        this.webSocket?.send(JSON.stringify({action: "subscribe", event, target}));

        this.subscriptions.push({event, target, handler});

        console.debug("Registered subscription for " + event + " event (target: " + target + ")");
    }

    public unsubscribe(event: string, target?: string) {
        if (this.webSocket?.readyState === WebSocket.OPEN) {
            this.webSocket?.send(JSON.stringify({action: "unsubscribe", event, target}));
        }

        _.remove(this.subscriptions, (subscription: ClientNotificationSubscription) => subscription.event === event && subscription.target);

        console.debug("Removed notification subscription for " + event + " event (target: " + target + ")");
    }

    private ping() {
        console.debug(this.webSocket?.readyState);

        if (this.webSocket?.readyState === WebSocket.OPEN) {
            this.webSocket?.send("PING");

            setTimeout(() => this.ping(), 5 * 1000);
        }
    }

}

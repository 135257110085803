import Axios from "axios";
import Country from "../domain/Country";
import {AppContext} from "../AppContext";
import {reaction} from "mobx";

class EnumService {

    private countries: Country[] = [];

    private countriesPromise: Promise<Country[]> = new Promise(resolve => {
        Axios.get("/api/admin/enums/countries").then(value => {
            this.countries = value.data;

            this.countries.sort((a, b) => a.translations[this.appContext.language].localeCompare(b.translations[this.appContext.language]));

            resolve(this.countries);
        })
    })

    constructor(private appContext: AppContext) {
        reaction(() => appContext.language, language => {
            this.countries.sort((a, b) => a.translations[language].localeCompare(b.translations[language]));
        });
    }

    public getCountries(): Promise<Country[]> {
        if (this.countries.length === 0) {
            return this.countriesPromise;
        } else {
            return Promise.resolve(this.countries);
        }
    }

}

export default EnumService;
export class X509Certificate {

    constructor(
        public serialNumber?: string,
        public subject?: string,
        public dnsNames?: string[],
        public issuer?: string,
        public fingerprint?: string,
        public notBefore?: Date,
        public notAfter?: Date,
        public derInBase64?: string) {
        //
    }

    public static fromPOJO(data: any) {
        const certificate = new X509Certificate();

        certificate.serialNumber = data.serialNumber;
        certificate.subject = data.subject;
        certificate.dnsNames = data.dnsNames;
        certificate.issuer = data.issuer;
        certificate.fingerprint = data.fingerprint;
        certificate.notBefore = new Date(data.notBefore);
        certificate.notAfter = new Date(data.notAfter);
        certificate.derInBase64 = data.derInBase64;

        return certificate;
    }

}

export class TlsCertificate {
    constructor(
        public id?: string,
        public type?: string,
        public subject?: string,
        public certificateChain?: X509Certificate[]) {
        //
    }

    public static fromPOJO(data: any) {
        const certificate = new TlsCertificate();

        certificate.id = data.id;
        certificate.type = data.type;
        certificate.subject = data.subject;
        certificate.certificateChain = data.certificateChain.map((value: any) => X509Certificate.fromPOJO(value));

        return certificate;
    }

}

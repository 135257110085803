import {ConfigProvider} from "antd";
import cs_CZ from "antd/lib/locale-provider/cs_CZ";
import en_US from "antd/lib/locale-provider/en_US";
import {useObserver} from "mobx-react-lite";
import React, {useContext, useEffect} from 'react';
import {RawIntlProvider} from "react-intl";
import HomePage from "./components/homepage/HomePage";
import {AppContextContext} from "./Contexts";
import {intl} from "./Intl";
import {useMediaQuery} from "react-responsive";
import {routes} from "./components/Routes";
import {useRoutes} from "react-router-dom";

function App() {
    const appContext = useContext(AppContextContext);
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})

    const isPublicRequest = window.location.pathname.startsWith("/fido/auth-request/") || window.location.pathname.startsWith("/register/");

    const route = useRoutes(routes);

    useEffect(() => {
        appContext.isTabletOrMobile = isTabletOrMobile;
    }, [isTabletOrMobile]);

    useEffect(() => {
        if (appContext.user === undefined && !isPublicRequest) {
            document.cookie = `User-Origin-URI=${window.btoa(window.location.href)}; path=/`;

            window.location.replace("/api/user/oidc/initiate");
        }
    }, [appContext.user]);

    return useObserver(() => (
        <>
            <RawIntlProvider value={intl} key={appContext.language}>
                <ConfigProvider locale={(appContext.language === 'cs') ? cs_CZ : en_US}>
                    {
                        appContext.isTabletOrMobile !== undefined &&

                        <div className="App">
                            {isPublicRequest && route}

                            {!isPublicRequest && appContext.user && <HomePage/>}
                        </div>
                    }
                </ConfigProvider>
            </RawIntlProvider>

            <div className={(appContext.overlayVisible) ? "overlay overlay-visible" : "overlay"}/>
        </>
    ));
}

export default App;

import {useEffect, useRef} from 'react';

function useInterval(callback: any, delay?: number) {
    const callbackRef = useRef<any>();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        function runCallback() {
            callbackRef.current();
        }

        if (delay !== undefined) {
            const timerId = window.setInterval(runCallback, delay);

            return function cleanup() {
                window.clearInterval(timerId);
            }
        }
    }, [delay]);

}

export default useInterval;

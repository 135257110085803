import React, {useContext, useEffect, useState} from "react";
import {AppContextContext, AuthServiceContext} from "../../Contexts";
import {Avatar, Menu, Popover} from "antd";
import styles from "./ProfileMenu.module.css";
import dayjs from "dayjs";
import {Duration} from "dayjs/plugin/duration";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import {LockFilled, LogoutOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router";
import {useIntlMessage} from "../../createIntlMessage";

const SESSION_CHECK_INTERVAL_IN_MS = 1000;

interface SessionTimeout {
    length: number;
    remaning: number;
}

function ProfileMenu() {
    const appContext = useContext(AppContextContext);
    const authService = useContext(AuthServiceContext);
    const intlMessage = useIntlMessage("home-page");
    const navigate = useNavigate();
    const [sessionTimeout, setSessionTimeout] = useState<SessionTimeout | null>(null);
    const [userMenuVisible, setUserMenuVisible] = useState(false);

    useEffect(() => {
        updateSessionTimeout();

        const timerHandle = setInterval(updateSessionTimeout, SESSION_CHECK_INTERVAL_IN_MS);

        return function cleaup() {
            clearInterval(timerHandle);
        }
    }, []);

    const profileMenuItems: ItemType[] = [
        {
            key: "password-change",
            icon: <LockFilled/>,
            label: intlMessage("common.password-change"),
            onClick: () => {
                navigate("/change-password");

                setUserMenuVisible(false);
            }
        },
        {
            type: "divider"
        },
        {
            key: "logout",
            icon: <LogoutOutlined/>,
            label: intlMessage("menu-logout"),
            onClick: () => {
                logout();

                setUserMenuVisible(false);
            }
        }
    ];

    return (
        <>
            <Popover trigger={"click"}
                     placement={"bottomRight"}
                     open={userMenuVisible}
                     destroyTooltipOnHide={true}
                     onOpenChange={visible => setUserMenuVisible(visible)}
                     overlayClassName={styles.avatarPopover}
                     content={
                         <>
                             <div className={styles.avatarPopoverHeader}>
                                 <div className={styles.avatarPopoverTitle}>{intlMessage("common.logged-in-as")}:</div>
                                 <div><b>{appContext.user?.username}</b></div>

                                 <div className={styles.avatarPopoverTitle}>{intlMessage("common.session-expires-in")}:</div>
                                 <div>{formatRemaining(dayjs.duration(sessionTimeout?.remaning!, 'seconds'))}</div>
                             </div>
                             <Menu mode={"inline"} items={profileMenuItems}/>
                         </>

                     }>
                <Avatar className={styles.avatar}>{appContext.user?.initials}</Avatar>
            </Popover>
        </>
    )

    function formatRemaining(duration: Duration) {
        const items = [];

        if (duration.days()) {
            items.push(`${duration.days()} d`);
        }

        if (duration.hours()) {
            items.push(`${duration.hours()} h`);
        }

        if (duration.minutes()) {
            items.push(`${duration.minutes()} m`);
        }

        if (duration.seconds()) {
            items.push(`${duration.seconds()} s`);
        }

        return items.join(" ");
    }

    function logout() {
        authService.logout();
    }

    function updateSessionTimeout() {
        if (appContext.sessionExpiresAt) {
            setSessionTimeout({
                length: dayjs(appContext.sessionExpiresAt).diff(dayjs(appContext.lastActivity), 'seconds'),
                remaning: dayjs(appContext.sessionExpiresAt).diff(dayjs(), 'seconds')
            })
        }
    }

}

export default ProfileMenu;
import {IntlMessageFormatter} from "../createIntlMessage";
import Principal from "./Principal";
import {AuthenticatorType} from "./AuthenticatorType";
import {FidoAuthenticatorAttachment} from "./FidoAuthenticatorAttachment";
import {X509Certificate} from "./TlsCertificate";

class Authenticator {
    constructor(
        public id?: string,
        public name?: string,
        public createdAt?: Date,
        public principals?: Principal[],
        public type?: AuthenticatorType,
        public state?: string,
        public friendlyInvitationId?: string,
        public invitationExpiresAt?: Date,
        public phoneNumber?: string,
        public deviceModel?: string,
        public version?: string,
        public publicKeyFingerprint?: string,
        public publicSshKey?: string,
        public registerUrl?: string,
        public publicId?: string,
        public smsOtpCodesEnabled?: boolean,
        public automaticMobilePush?: boolean,
        public automaticSms?: boolean,
        public discoverable?: boolean,
        public attachment?: FidoAuthenticatorAttachment,
        public userVerificationRequired?: boolean,
        public invitationVerificationCodeRequired?: boolean,
        public fingerprint?: string,
        public certificate?: X509Certificate) {
        //
    }

    public static fromPOJO(data: any) {
        const authenticator = new Authenticator();

        authenticator.id = data.id;
        authenticator.name = data.name;
        authenticator.createdAt = (data.createdAt) ? new Date(data.createdAt) : undefined;
        authenticator.type = data.type;
        authenticator.state = data.state;
        authenticator.friendlyInvitationId = data.friendlyInvitationId;
        authenticator.invitationExpiresAt = (data.invitationExpiresAt) ? new Date(data.invitationExpiresAt) : undefined;
        authenticator.phoneNumber = data.phoneNumber;
        authenticator.deviceModel = data.deviceModel;
        authenticator.version = data.version;
        authenticator.publicKeyFingerprint = data.publicKeyFingerprint;
        authenticator.publicSshKey = data.publicKeyFingerprint;
        authenticator.registerUrl = data.registerUrl;
        authenticator.publicId = data.publicId;
        authenticator.smsOtpCodesEnabled = data.smsOtpCodesEnabled;
        authenticator.automaticMobilePush = data.automaticMobilePush;
        authenticator.automaticSms = data.automaticSms;
        authenticator.discoverable = data.discoverable;
        authenticator.attachment = data.attachment;
        authenticator.userVerificationRequired = data.userVerificationRequired;
        authenticator.invitationVerificationCodeRequired = data.invitationVerificationCodeRequired;
        authenticator.fingerprint = data.fingerprint;
        authenticator.certificate = (data.certificate) ? X509Certificate.fromPOJO(data.certificate) : undefined;

        if (data.principals) {
            authenticator.principals = data.principals.map((principal: any) => Principal.fromPOJO(principal))
        }

        if (data.basicAttributes) {
            Object.assign(authenticator, data.basicAttributes);
        }

        return authenticator;
    }

    public get deviceInfo() {
        if (this.type === AuthenticatorType.MOBILE) {
            if (this.state === 'REGISTERED') {
                return (this.phoneNumber ? this.phoneNumber + " - " : '') + this.deviceModel + " (" + this.version + ")";
            } else {
                return this.phoneNumber;
            }
        } else if (this.type === AuthenticatorType.YUBIKEY) {
            return (this.state === 'REGISTERED') ? "PublicID: " + this.publicId : "";
        } else if (this.type === AuthenticatorType.FIDO) {
            return (this.state === 'REGISTERED' && this.deviceModel && this.version) ? this.deviceModel + " (" + this.version + ")" : "";
        } else {
            return "";
        }
    }

    public static formatState(intlMessage: IntlMessageFormatter, state?: string) {
        switch (state) {
            case "NONE":
                return intlMessage("authenticator-state.NONE");
            case "INVITED":
                return intlMessage("authenticator-state.INVITED");
            case "REGISTERED":
                return intlMessage("authenticator-state.REGISTERED");
            default:
                return "?";
        }
    }

    public static formatType(intlMessage: IntlMessageFormatter, type?: AuthenticatorType) {
        switch (type) {
            case AuthenticatorType.MOBILE:
                return intlMessage("authenticator-type.MOBILE");
            case AuthenticatorType.YUBIKEY:
                return intlMessage("authenticator-type.YUBIKEY");
            case AuthenticatorType.FIDO:
                return intlMessage("authenticator-type.FIDO");
            case AuthenticatorType.TLS_CERTIFICATE:
                return intlMessage("authenticator-type.TLS_CERTIFICATE");
            case AuthenticatorType.PUBLIC_SSH_KEY:
                return intlMessage("authenticator-type.PUBLIC_SSH_KEY");
            default:
                return "?";
        }
    }

}

export default Authenticator;

import Authenticator from "./Authenticator";

class AuthenticatorAssignment {
    constructor(
        public id?: string,
        public automaticMobilePush?: boolean,
        public automaticSms?: boolean,
        public authenticator?: Authenticator) {
        //
    }

    public static fromPOJO(data: any) {
        const assignment = new AuthenticatorAssignment();

        assignment.id = data.id;
        assignment.automaticMobilePush = data.automaticMobilePush;
        assignment.automaticSms = data.automaticSms;

        assignment.authenticator = Authenticator.fromPOJO(data.authenticator);

        return assignment;
    }
}

export default AuthenticatorAssignment;

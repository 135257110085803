export enum AuthenticatorType {
    MOBILE = "MOBILE",
    YUBIKEY = "YUBIKEY",
    FIDO = "FIDO",
    TLS_CERTIFICATE = "TLS_CERTIFICATE",
    PUBLIC_SSH_KEY = "PUBLIC_SSH_KEY"
}

export function isInvitationSupported(type?: AuthenticatorType) {
    switch (type) {
        case AuthenticatorType.MOBILE:
        case AuthenticatorType.YUBIKEY:
        case AuthenticatorType.FIDO:
            return true;
        default:
            return false;
    }
}


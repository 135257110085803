import {observable} from "mobx";
import User from "./domain/User";
import {AppConfig} from "./AppConfig";
import {AuthenticatedAppConfig} from "./AuthenticatedAppConfig";
import dayjs from "dayjs";

export class AppContext {

    @observable public language = "cs";

    @observable public user?: User;

    @observable public lastActivity?: Date;

    @observable public sessionExpiresAt?: Date;

    @observable public config?: AppConfig;

    @observable public isTabletOrMobile?: boolean;

    @observable public actionConfirmationCode?: string;

    @observable public authenticatedConfig?: AuthenticatedAppConfig;

    @observable public serviceWorkerRegistration?: ServiceWorkerRegistration;

    @observable public overlayVisible = false;

    public get expiresInSeconds() {
        return dayjs(this.sessionExpiresAt).diff(dayjs(), 'second');
    }

}

import {IntlMessageFormatter} from "../createIntlMessage";
import UAParser from "ua-parser-js";

export enum AuthenticatorProfile {
    GENERAL = "GENERAL",
    WINDOWS = "WINDOWS",
    ANDROID = "ANDROID",
    IOS = "IOS",
    U2F = "U2F",
    HW_TOKEN_DISCOVERABLE = "HW_TOKEN_DISCOVERABLE",
    HW_TOKEN_NON_DISCOVERABLE = "HW_TOKEN_NON_DISCOVERABLE"
}

export function suggestProfile() {
    const parser = new UAParser(window.navigator.userAgent);

    const os = parser.getOS();

    if (os.name?.toLowerCase().startsWith('windows')) {
        return AuthenticatorProfile.WINDOWS;
    } else if (os.name?.toLowerCase().startsWith('android')) {
        return AuthenticatorProfile.ANDROID;
    } else if (os.name?.toLowerCase().startsWith('ios')) {
        return AuthenticatorProfile.IOS;
    } else {
        return AuthenticatorProfile.HW_TOKEN_NON_DISCOVERABLE;
    }
}

export function getRelevantProfiles(): AuthenticatorProfile[] {
    const parser = new UAParser(window.navigator.userAgent);

    const os = parser.getOS();

    if (os.name?.toLowerCase().startsWith('windows')) {
        return [
            AuthenticatorProfile.WINDOWS,
            AuthenticatorProfile.HW_TOKEN_DISCOVERABLE,
            AuthenticatorProfile.HW_TOKEN_NON_DISCOVERABLE,
            AuthenticatorProfile.U2F,
            AuthenticatorProfile.GENERAL,
        ];
    } else if (os.name?.toLowerCase().startsWith('android')) {
        return [
            AuthenticatorProfile.ANDROID,
            AuthenticatorProfile.HW_TOKEN_DISCOVERABLE,
            AuthenticatorProfile.HW_TOKEN_NON_DISCOVERABLE,
            AuthenticatorProfile.U2F,
            AuthenticatorProfile.GENERAL,
        ];
    } else if (os.name?.toLowerCase().startsWith('ios')) {
        return [
            AuthenticatorProfile.IOS,
            AuthenticatorProfile.HW_TOKEN_DISCOVERABLE,
            AuthenticatorProfile.HW_TOKEN_NON_DISCOVERABLE,
            AuthenticatorProfile.U2F,
            AuthenticatorProfile.GENERAL,
        ];
    } else {
        return [
            AuthenticatorProfile.GENERAL,
            AuthenticatorProfile.WINDOWS,
            AuthenticatorProfile.ANDROID,
            AuthenticatorProfile.IOS,
            AuthenticatorProfile.HW_TOKEN_DISCOVERABLE,
            AuthenticatorProfile.HW_TOKEN_NON_DISCOVERABLE,
            AuthenticatorProfile.U2F,
        ];
    }
}

export function formatAuthenticatorProfile(intlMessage: IntlMessageFormatter, profile?: AuthenticatorProfile) {
    switch (profile) {
        case AuthenticatorProfile.GENERAL:
            return intlMessage("authenticator-profile.GENERAL");
        case AuthenticatorProfile.WINDOWS:
            return intlMessage("authenticator-profile.WINDOWS");
        case AuthenticatorProfile.ANDROID:
            return intlMessage("authenticator-profile.ANDROID");
        case AuthenticatorProfile.IOS:
            return intlMessage("authenticator-profile.IOS");
        case AuthenticatorProfile.U2F:
            return intlMessage("authenticator-profile.U2F");
        case AuthenticatorProfile.HW_TOKEN_DISCOVERABLE:
            return intlMessage("authenticator-profile.HW_TOKEN_DISCOVERABLE");
        case AuthenticatorProfile.HW_TOKEN_NON_DISCOVERABLE:
            return intlMessage("authenticator-profile.HW_TOKEN_NON_DISCOVERABLE");
        default:
            return "?";
    }
}

export function formatAuthenticatorProfileHelp(intlMessage: IntlMessageFormatter, profile?: AuthenticatorProfile) {
    switch (profile) {
        case AuthenticatorProfile.GENERAL:
            return intlMessage("authenticator-profile.GENERAL-help");
        case AuthenticatorProfile.WINDOWS:
            return intlMessage("authenticator-profile.WINDOWS-help");
        case AuthenticatorProfile.ANDROID:
            return intlMessage("authenticator-profile.ANDROID-help");
        case AuthenticatorProfile.IOS:
            return intlMessage("authenticator-profile.IOS-help");
        case AuthenticatorProfile.U2F:
            return intlMessage("authenticator-profile.U2F-help");
        case AuthenticatorProfile.HW_TOKEN_DISCOVERABLE:
            return intlMessage("authenticator-profile.HW_TOKEN_DISCOVERABLE-help");
        case AuthenticatorProfile.HW_TOKEN_NON_DISCOVERABLE:
            return intlMessage("authenticator-profile.HW_TOKEN_NON_DISCOVERABLE-help");
        default:
            return "?";
    }
}

export function deriveAttributesForAuthenticatorProfile(profile: AuthenticatorProfile, values: any) {
    switch (profile) {
        case AuthenticatorProfile.WINDOWS:
        case AuthenticatorProfile.IOS:
            return {
                discoverableCredential: true,
                userVerificationRequired: true
            };
        case AuthenticatorProfile.ANDROID:
            return {
                discoverableCredential: false,
                userVerificationRequired: true
            };
        case AuthenticatorProfile.U2F:
            return {
                discoverableCredential: false,
                userVerificationRequired: false
            };
        case AuthenticatorProfile.HW_TOKEN_DISCOVERABLE:
            return {
                discoverableCredential: true,
                userVerificationRequired: true
            };
        case AuthenticatorProfile.HW_TOKEN_NON_DISCOVERABLE:
            return {
                discoverableCredential: false,
                userVerificationRequired: true
            };
    }
}

import AuthRequest from "../domain/AuthRequest";
import Axios, {AxiosResponse} from "axios";

class AuthRequestService {

    public get(authRequestId: string): Promise<AuthRequest> {
        return Axios.get(`/fido/auth-request/${authRequestId}`).then(value => {
            return AuthRequest.fromPOJO(value.data);
        })
    }

    public getPending(): Promise<AuthRequest[]> {
        return Axios.get(`/auth-requests/pending`).then(response => {
            return response.data.map((value: any) => AuthRequest.fromPOJO(value));
        })
    }

    public allow(authRequestId: string, fidoChallengeId: string, assertionResponse: string): Promise<AxiosResponse> {
        return Axios.post(`/fido/auth-request/${authRequestId}`, {fidoChallengeId, assertionResponse});
    }

}

export default AuthRequestService;
import DateTimePeriod from "../../domain/DateTimePeriod";
import dayjs from "dayjs";
import * as colors from "@ant-design/colors";
import {ActiveElement, Chart, ChartConfiguration, ChartDataset, ChartEvent, DefaultDataPoint} from "chart.js";

export function computeChartMinMax(period: DateTimePeriod): { min: number, max: number } {
    switch (period) {
        case DateTimePeriod.Day:
            return {
                min: dayjs().subtract(1, 'day').endOf('hour').valueOf(),
                max: dayjs().endOf('hour').valueOf()
            };
        case DateTimePeriod.Week:
            return {
                min: dayjs().subtract(1, 'week').endOf('day').valueOf(),
                max: dayjs().endOf('day').valueOf()
            };
        case DateTimePeriod.Quarter:
            return {
                min: dayjs().subtract(3, 'month').endOf('day').valueOf(),
                max: dayjs().endOf('day').valueOf()
            };
        default:
            return {
                min: dayjs().subtract(1, 'month').endOf('day').valueOf(),
                max: dayjs().endOf('day').valueOf()
            };
    }
}

export function defaultChartOptions(
    period: DateTimePeriod,
    onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart)=> void,
    datasets: ChartDataset<"bar", DefaultDataPoint<"bar">>[]): ChartConfiguration<"bar"> {
    const chartMinMax = computeChartMinMax(period);

    return {
        type: 'bar',
        data: {
            datasets,
        },
        options: {
            onClick,
            scales: {
                xAxis: {
                    type: 'time',
                    time: {
                        unit: timeUnitForDateTimePeriod(period),
                        tooltipFormat: (period === DateTimePeriod.Day) ? "lll" : "ll",
                        displayFormats: {
                            hour: 'HH:00'
                        }
                    },
                    min: chartMinMax.min,
                    max: chartMinMax.max
                },
                yAxis: {
                    beginAtZero: true,
                    suggestedMax: 10,
                    ticks: {
                        stepSize: 1,
                    }
                }
            }
        }
    }
}

export function timeUnitForDateTimePeriod(period: DateTimePeriod) {
    switch (period) {
        case DateTimePeriod.Day:
            return "hour";
        case DateTimePeriod.Week:
            return "day";
        case DateTimePeriod.Month:
            return "day";
        case DateTimePeriod.Quarter:
            return "week";
    }
}

export const chartColors = [
    colors.blue[5],
    colors.gold[5],
    colors.cyan[5],
    colors.purple[5],
    colors.magenta[5],
    colors.green[5],
    colors.red[6],
    colors.volcano[4]
];
import {IntlMessageFormatter} from "../createIntlMessage";

export enum FidoAuthenticatorAttachment {
    PLATFORM = "platform",
    CROSS_PLATFORM = "cross-platform",
    UNKNOWN = "unknown"
}

export function formatFidoAuthenticatorAttachment(intlMessage: IntlMessageFormatter, attachment?: FidoAuthenticatorAttachment) {
    switch (attachment) {
        case FidoAuthenticatorAttachment.PLATFORM:
            return intlMessage("fido-authenticator-attachment.PLATFORM");
        case FidoAuthenticatorAttachment.CROSS_PLATFORM:
            return intlMessage("fido-authenticator-attachment.CROSS_PLATFORM");
        case FidoAuthenticatorAttachment.UNKNOWN:
            return intlMessage("fido-authenticator-attachment.UNKNOWN");
        default:
            return "?";
    }
}

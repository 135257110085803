import React, {useContext, useEffect, useRef} from "react";
import Chart from 'chart.js/auto';
import 'chartjs-adapter-dayjs-3';
import {useIntlMessage} from "../../createIntlMessage";
import DateTimePeriod from "../../domain/DateTimePeriod";
import dayjs from "dayjs";
import {StatisticsServiceContext} from "../../Contexts";
import {ActiveElement, ChartEvent} from "chart.js";
import {useNavigate} from "react-router";
import {defaultChartOptions} from "./ChartUtils";
import * as colors from '@ant-design/colors';

interface IProps {
    period: DateTimePeriod;
}

function AuthRequestStatistics({period}: IProps) {
    const statisticsService = useContext(StatisticsServiceContext);
    const navigate = useNavigate();
    const chartElRef = useRef<HTMLCanvasElement>(null);
    const chartRef = useRef<Chart>();
    const intlMessage = useIntlMessage("auth-request-statistics");

    useEffect(() => {
        statisticsService.getStatistics(period, "auth-requests").then(data => {
            const allowed = data.filter(value => value.type === 'AUTH_REQUEST_ALLOWED');
            const timedOut = data.filter(value => value.type === 'AUTH_REQUEST_TIMED_OUT');
            const denied = data.filter(value => value.type === 'AUTH_REQUEST_DENIED');

            chartRef.current!.data.datasets[0].data = denied.map(value => ({
                x: new Date(value.dateTime).getTime(), y: value.count, type: value.type
            }))

            chartRef.current!.data.datasets[1].data = timedOut.map(value => ({
                x: new Date(value.dateTime).getTime(), y: value.count, type: value.type
            }))

            chartRef.current!.data.datasets[2].data = allowed.map(value => ({
                x: new Date(value.dateTime).getTime(), y: value.count, type: value.type
            }))

            chartRef.current!.update();
        })

        chartRef.current = new Chart((chartElRef.current as HTMLCanvasElement),
            Object.assign(defaultChartOptions(period, onClick, [
                {
                    label: intlMessage("denied"),
                    stack: "",
                    data: [],
                    backgroundColor: colors.red[5]
                },
                {
                    label: intlMessage("timedOut"),
                    stack: "",
                    data: [],
                    backgroundColor: colors.orange[4]
                },
                {
                    label: intlMessage("allowed"),
                    stack: "",
                    data: [],
                    backgroundColor: colors.green[6]
                }
            ]))
        );

        return () => {
            chartRef.current?.destroy();
        }
    }, [period]);

    return (
        <canvas width="400" height="250" ref={chartElRef}/>
    )

    function onClick(_event: ChartEvent, elements: ActiveElement[], chart: Chart) {
        const element = elements[0];

        const item: any = chart.data.datasets[element.datasetIndex].data[element.index];

        const timestampFrom = dayjs(item.x).toISOString();
        const timestampTo = (period === DateTimePeriod.Day)
            ? dayjs(item.x).add(1, 'hour').toISOString()
            : dayjs(item.x).add(1, 'day').toISOString();

        const filter: any = {
            typeHints: {"timestampFrom": "dayjs", "timestampTo": "dayjs"},
            values: {
                types: [item.type],
                severity: "INFO",
                timestampFrom,
                timestampTo
            }
        }

        const searchParams = new URLSearchParams();

        searchParams.set("filter", JSON.stringify(filter))

        navigate(`/audit-logs?${searchParams}`);
    }

}

export default AuthRequestStatistics;
